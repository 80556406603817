// @flow
import React from 'react';

// styles
import './line.scss';

type Props = {
  isWhite?: boolean,
}

const Line = ({ isWhite }: Props) => <div className={`line ${isWhite ? 'white' : ''}`} />;

Line.defaultProps = {
  isWhite: false,
};

export default Line;
