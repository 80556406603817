// @flow
import axios from 'axios';

import env from 'CONFIG/env';

const { apiUrl } = env;

class HttpService {
  // request :: (object) => axios instance
  static request(receivedHeader = {}) {
    return axios.create({
      baseURL: apiUrl,
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        ...receivedHeader,
      },
    });
  }

  // post :: (string, object, object) => promise
  static post(endpoint, data, headers = {}) {
    return new Promise((resolve, reject) => {
      this.request(headers).post(endpoint, data).then((response) => {
        if (response.data.errorcode === 0) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }

  // get :: (string, object) => promise
  static get(endpoint, headers = {}) {
    return new Promise((resolve, reject) => {
      this.request(headers).get(endpoint).then((response) => {
        if (response.data.errorcode === 0) {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }
}

export default HttpService;
