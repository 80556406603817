// @flow
import React from 'react';

// styles
import './sectionTitleWithText.scss';
import Line from 'COMPONENTS/Line';

type Props = {
  children: React.Node,
  isWhiteLine?: boolean,
}

const SectionTitleWithText = ({ children, isWhiteLine }: Props) => (
  <div className="section_title_with_text">
    {children}
    <Line isWhite={isWhiteLine} />
  </div>
);

SectionTitleWithText.defaultProps = {
  isWhiteLine: false,
};

export default SectionTitleWithText;
