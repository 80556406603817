import { UserService } from 'SERVICES';

export default (notificationID, toggleNotificationSeen) => {
  toggleNotificationSeen({ isSeen: true });
  UserService.notificationSeen({
    notificationID,
  }).then(() => {
  }).catch((error) => {
    console.log(error);
  });
};
