// @flow
import React from 'react';

// components
// import Footer from 'COMPONENTS/Auth/Footer';

// other
import env from 'CONFIG/env';

// styles
import 'bootstrap/scss/bootstrap.scss';
import 'STYLES/main.scss';
import './errorPageContainer.scss';

const { imagesPath } = env;

const ErrorPageContainer = ({ children, className, ...rest }: Props) => (
  <div
    className={`error-page-container ${className}`}
    style={{ backgroundImage: `url(${imagesPath}/error_page_background.svg)` }}
    {...rest}
  >
    <div className="content-container">
      { children }
    </div>
    {/*<Footer imagesPath={imagesPath} />*/}
  </div>
);

export default ErrorPageContainer;
