// @flow
import React from 'react';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// other
import env from 'CONFIG/env';
import toggleModalNContent from './toggleModalNContent';

const { imagesPath } = env;

type Props = {
  children: React.Node,
  toggleModal: Function,
  onCloseFunc?: Function,
  clearMessage: Function,
  modalId: string,
  messageId: string,
}

const ModalContent = ({
  children, toggleModal, modalId, clearMessage, messageId, onCloseFunc,
}: Props) => (
  <div className="modal_content">
    <div className="close_icon_container">
      <img
        className="close_icon"
        src={`${imagesPath}/close_18x18_icon_grey.svg`}
        onClick={() => {
          toggleModalNContent({
            toggleModal, modalId, clearMessage, messageId,
          });
          if (onCloseFunc) {
            onCloseFunc();
          }
        }}
      />
    </div>
    {children}
  </div>
);

ModalContent.defaultProps = {
  onCloseFunc: () => null,
};

const mapStateToProps = ({ uiInteractions }) => ({ uiInteractions });
const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(actionCreators.toggleModal, dispatch),
  clearMessage: bindActionCreators(actionCreators.clearMessage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
