import { UserService } from 'SERVICES';
import { SubmissionError } from 'redux-form';

// func :: obj, func, func => bool || promise
export default (formData, toggleLoading, callSuccess) => {
  toggleLoading(true);
  return UserService.resetPassword(formData.values).then(() => {
    toggleLoading(false);
    callSuccess();
  }).catch(({ data }) => {
    const { message } = data;
    const errors = {};
    let _error = '';

    toggleLoading(false);

    if (typeof message === 'object' && message !== null) {
      const keys = Object.keys(message);
      keys.map((key) => {
        errors[key] = message[key][0];
      });
    } else {
      _error = message;
    }

    throw new SubmissionError({
      ...errors,
      _error,
    });
  });
};
