// @flow
import { UserService } from 'SERVICES';

import { pixel } from 'CONFIG';

type Args = {
  e: Event,
  callCustomMessage: Function,
  values: Object | undefined,
  storeInfo: Object,
  makeRequest: boolean,
  storeId: string,
  paymentMethod: 1 | 2,
  type: string,
  toggleLoading: Function,
}

const { trackers } = pixel;

export default ({
  e, values, makeRequest, storeId, paymentMethod, callCustomMessage, type, toggleLoading, toggleCustomLoader, storeInfo, source, couponValidCode,
}: Args) => {
  e.preventDefault();
  if (makeRequest) {
    const details = [{
      type, billing_period: values.charge_period, product_type: 'store', product_id: storeId,
    }];

    if (couponValidCode) {
      details[0].code = couponValidCode;
    }

    const dataForSubmit = {
      details,
      paymentMethod,
    };

    toggleLoading(true);
    toggleCustomLoader({ id: `checkout_btn_${paymentMethod}`, isLoading: true });

    UserService.upgradeCheckout(dataForSubmit).then(({ data }) => {
      trackers.subscribe({
        paymentMethod: paymentMethod === 1 ? 'invoice' : 'card',
        chargePeriod: values.charge_period,
        storeUrl: storeInfo.url,
        source,
      });
      if (paymentMethod === 1) {
        window.location = data.data[0];
      } else {
        callCustomMessage({
          id: 'upgradeModalMessage',
          isMessage: true,
          messageType: 'success',
          message: data.data[0],
        });
      }
      toggleLoading(false);
      toggleCustomLoader({ id: `checkout_btn_${paymentMethod}`, isLoading: false });
    }).catch(({ data }) => {
      callCustomMessage({
        id: 'upgradeModalMessage',
        isMessage: true,
        messageType: 'error',
        message: data.message,
      });
      toggleLoading(false);
      toggleCustomLoader({ id: `checkout_btn_${paymentMethod}`, isLoading: false });
    });
  }
};
