// @flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18next';
import { ParallaxProvider, ParallaxBanner, Parallax } from 'react-scroll-parallax';
import { Animated } from 'react-animated-css';
import Head from 'next/head';
import { Link } from 'ROOT/routes';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators, actionTypes } from 'ACTIONS';

// components
// import Card from 'COMPONENTS/Card';
import Button from 'COMPONENTS/Button';
import Header from 'COMPONENTS/Landing/Header';
import Footer from 'COMPONENTS/Landing/Footer';
import Hero from 'COMPONENTS/Landing/Hero';
import DoYourselfSection from 'COMPONENTS/Landing/DoYourselfSection';
import ApplicationSection from 'COMPONENTS/Landing/ApplicationSection';
import CheckoutSection from 'COMPONENTS/Landing/CheckoutSection';
import FeaturesSection from 'COMPONENTS/Landing/FeaturesSection';
import TrailSection from 'COMPONENTS/Landing/TrailSection';
import { Notification } from 'COMPONENTS/Notfication';

// other
import { pixel, env } from 'CONFIG';

const { trackers, registerKeys } = pixel;

// styles
import 'bootstrap/scss/bootstrap.scss';
import 'STYLES/main.scss';
import './welcome.scss';

// other
import { isDomObjInViewport } from 'FUNCTIONS';
import { rHomepage, rLandingFooter, rLandingHeader } from 'REQUESTS';

// data
import landingData from './landingData';

const {
  imagesPath, baseUrl, userLanguage,
} = env;

const {
  unishopLogoName, footer, unishopDarkLogoName,
} = landingData;

const GenerateNotificationsFromQuery = (query, t) => {
  if (Object.keys(query).length <= 0) {
    return null;
  }
  const objForRender = {
    text: '',
    type: '',
  };

  if (parseInt(query.activate_account, 10) === 1) {
    objForRender.text = t('back.auth_register_active_successfully');
    objForRender.type = 'success';
  } else if (parseInt(query.activate_account, 10) === 0) {
    objForRender.text = t('back.auth_register_active_unsuccessfully');
    objForRender.type = 'error';
  } else if (query.unblock_account) {
    objForRender.text = t('back.notification_user_unblock_account');
    objForRender.type = 'success';
  } else if (query.session_ended) {
    objForRender.text = t('back.notification_user_log_outed');
    objForRender.type = 'error';
  } else {
    return null;
  }

  return (
    <Notification id="login-notification" show type={objForRender.type}>
      { objForRender.text }
    </Notification>
  );
};

class Homepage extends PureComponent {
  static async getInitialProps({
    setHomepageData, landing, contentLoading, toggleContentLoading, loading, toggleLoading, setLandingFooterData, setLandingHeaderData, reduxStore, toggleModal, query
  }) {
    await reduxStore.dispatch(rHomepage({ dispatch: reduxStore.dispatch }));
    return ({
      setHomepageData, landing, contentLoading, toggleContentLoading, loading, toggleLoading, setLandingFooterData, setLandingHeaderData, toggleModal, query
    });
  }

  static propTypes = {
    setHomepageData: PropTypes.func.isRequired,
    setLandingFooterData: PropTypes.func.isRequired,
    toggleContentLoading: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    setLandingHeaderData: PropTypes.func.isRequired,
    landing: PropTypes.object,
    contentLoading: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  state = {
    isVisible: false,
    isSocialCommerceSeen: 0,
    isApplicationSeen: 0,
    isCheckoutSectionSeen: 0,
    isPremiumSectionSeen: 0,
    isFeaturesSectionSeen: 0,
  };

  // componentWillMount() {
  //   const { setLandingFooterData, toggleLoading, setLandingHeaderData } = this.props;
  //   rLandingFooter({ setLandingFooterData, toggleLoading });
  //   rLandingHeader({ setLandingHeaderData });
  // }

  // componentDidMount() {
  //   this.setState({
  //     isVisible: true,
  //   });
  //   document.addEventListener('scroll', () => {
  //     if(window.location.pathname !== '/register' && window.location.pathname !== '/signin' && window.location.pathname !== '/reset') {
  //           this.setState({
  //             isSocialCommerceInViewport: isDomObjInViewport(document.getElementsByClassName('social-commerce-section')[0]),
  //             isSocialCommerceSeen: this.state.isSocialCommerceInViewport ? this.state.isSocialCommerceSeen + 1 : this.state.isSocialCommerceSeen,
  //
  //             isApplicationSectionInViewport: isDomObjInViewport(document.getElementsByClassName('use_free_app_section')[0]),
  //             isApplicationSeen: this.state.isApplicationSectionInViewport ? this.state.isApplicationSeen + 1 : this.state.isApplicationSeen,
  //
  //             isCheckoutSectionVisible: isDomObjInViewport(document.getElementsByClassName('checkoutSection')[0]),
  //             isCheckoutSectionSeen: this.state.isCheckoutSectionVisible ? this.state.isCheckoutSectionSeen + 1 : this.state.isCheckoutSectionSeen,
  //
  //             // isPremiumSectionVisible: isDomObjInViewport(document.getElementsByClassName('premiumSection')[0]),
  //             // isPremiumSectionSeen: this.state.isPremiumSectionVisible ? this.state.isPremiumSectionSeen + 1 : this.state.isPremiumSectionSeen,
  //
  //             isFeaturesSectionVisible: isDomObjInViewport(document.getElementsByClassName('featuresSection')[0]),
  //             isFeaturesSectionSeen: this.state.isFeaturesSectionVisible ? this.state.isFeaturesSectionSeen + 1 : this.state.isFeaturesSectionSeen,
  //           });
  //         }
  //   });
  // }

  render() {
    const {
      contentLoading, loading, landing, toggleModal, query,
    } = this.props;
    const {
      isVisible,
      isSocialCommerceInViewport,
      isApplicationSectionInViewport,
      isCheckoutSectionVisible,
      isPremiumSectionVisible,
      isFeaturesSectionVisible,
      isSocialCommerceSeen,
      isApplicationSeen,
      isCheckoutSectionSeen,
      isPremiumSectionSeen,
      isFeaturesSectionSeen,
    } = this.state;

    const { homepageData } = landing;

    return (
      <I18n>
        {
          t => (
            <ParallaxProvider>
              {
                GenerateNotificationsFromQuery(query, t)
              }
              <span className="welcome-page">
                <Head>
                  <title>{homepageData['meta-tags'][userLanguage].home.extras.meta_title}</title>
                  <meta name="description" content={homepageData['meta-tags'][userLanguage].home.extras.meta_description} />
                  <meta name="keywords" content={homepageData['meta-tags'][userLanguage].home.extras.meta_keywords} />

                  <meta property="og:type" content="website" />
                  <meta property="og:locale" content="ka_GE" />

                  <meta property="og:title" content={homepageData['meta-tags'][userLanguage].home.extras.og_title} />
                  <meta property="og:description" content={homepageData['meta-tags'][userLanguage].home.extras.og_description} />
                  <meta property="og:url" content={homepageData['meta-tags'][userLanguage].home.extras.og_url} />
                  <meta property="og:image" content={`${baseUrl}/${homepageData['meta-tags'][userLanguage].home.extras.og_image}`} />
                  <meta property="og:site_name" content={homepageData['meta-tags'][userLanguage].home.extras.og_site_name} />
                </Head>
                {/*<Header*/}
                {/*  isTransparent*/}
                {/*  imagesPath={imagesPath}*/}
                {/*  unishopLogoName={unishopLogoName}*/}
                {/*  unishopDarkLogoName={unishopDarkLogoName}*/}
                {/*  headerData={this.props.landing.headerData}*/}
                {/*/>*/}
                {/*<Hero*/}
                {/*  title={homepageData.modules[userLanguage].hero.header[0].extras.title}*/}
                {/*  description={homepageData.modules[userLanguage].hero.header[0].extras.description}*/}
                {/*  buttonText={homepageData.modules[userLanguage].hero.header[0].extras.button_title}*/}
                {/*  buttonLink={homepageData.modules[userLanguage].hero.header[0].extras.button_link}*/}
                {/*  imageName={homepageData.modules[userLanguage].hero.header[0].extras.image}*/}
                {/*  mobileImage={homepageData.modules[userLanguage].hero.header[0].extras.mobile_image || homepageData.modules[userLanguage].hero.header[0].extras.image}*/}
                {/*  imageGraphic={homepageData.modules[userLanguage].hero.header[0].extras.front_image}*/}
                {/*  baseUrl={baseUrl}*/}
                {/*  toggleModal={toggleModal}*/}
                {/*  isVisible*/}
                {/*/>*/}

                {/*<DoYourselfSection*/}
                {/*  title={homepageData.modules[userLanguage].codingNecessary.necessaryTexts[0].extras.title}*/}
                {/*  text={homepageData.modules[userLanguage].codingNecessary.necessaryTexts[0].extras.description}*/}
                {/*  featuresArr={homepageData.modules[userLanguage].codingNecessary.necessaryIcons}*/}
                {/*  baseUrl={baseUrl}*/}
                {/*  isVisible={isVisible}*/}
                {/*/>*/}

                {/* <ParallaxBanner */}
                {/* className="homepage-parallax" */}
                {/* offsetYMax={80} */}
                {/* offsetYMin={-20} */}
                {/* layers={[ */}
                {/* { */}
                {/* image: `${baseUrl}/${socialCommerceData.image_1}`, */}
                {/* amount: 0.1, */}
                {/* slowerScrollRate: false, */}
                {/* }, */}
                {/* { */}
                {/* image: `${baseUrl}/${socialCommerceData.image_2}`, */}
                {/* amount: 0.2, */}
                {/* slowerScrollRate: false, */}
                {/* }, */}
                {/* ]} */}
                {/* style={{ */}
                {/* height: '550px', */}
                {/* }} */}
                {/* > */}

                {/*<div*/}
                {/*  className="social-commerce-section white-texts"*/}
                {/*  style={{ backgroundImage: `url(${baseUrl}/${homepageData.modules[userLanguage].socialCommerce.socialCommerce[0].extras.image_1})` }}*/}
                {/*>*/}
                {/*  <div className="inner-container">*/}
                {/*    <div className="dark-background">*/}
                {/*      <Animated*/}
                {/*        className="content"*/}
                {/*        animationIn="fadeInUp"*/}
                {/*        animationOut="fadeOutDown"*/}
                {/*        isVisible={!!(isSocialCommerceInViewport || isSocialCommerceSeen)}*/}
                {/*      >*/}
                {/*        <div*/}
                {/*          className="text-content"*/}
                {/*          dangerouslySetInnerHTML={{ __html: homepageData.modules[userLanguage].socialCommerce.socialCommerce[0].extras.description }}*/}
                {/*        />*/}
                {/*        <a*/}
                {/*          href=""*/}
                {/*          onClick={() => {*/}
                {/*            trackers.registerCustom(registerKeys.SOC_MEDIA);*/}
                {/*          }*/}
                {/*          }*/}
                {/*        >*/}
                {/*          <Button isTransparent isWhiteBordered>*/}
                {/*            <Link to="Register">*/}
                {/*            {homepageData.modules[userLanguage].socialCommerce.socialCommerce[0].extras.button_title}*/}
                {/*            </Link>*/}
                {/*          </Button>*/}
                {/*        </a>*/}
                {/*      </Animated>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<ApplicationSection*/}
                {/*  title={homepageData.modules[userLanguage].useAppFeatures.featuresText[0].extras.title}*/}
                {/*  text={homepageData.modules[userLanguage].useAppFeatures.featuresText[0].extras.description}*/}
                {/*  slidesArr={homepageData.modules[userLanguage].useAppFeatures.featuresRepeat}*/}
                {/*  baseUrl={baseUrl}*/}
                {/*  downloadFromIcons={homepageData.modules[userLanguage].useAppFeatures.featuresIcons}*/}
                {/*  isVisible={!!(isApplicationSectionInViewport || isApplicationSeen)}*/}
                {/*/>*/}

                {/*<CheckoutSection*/}
                {/*  title={homepageData.modules[userLanguage].checkout.checkout[0].extras.title}*/}
                {/*  text={homepageData.modules[userLanguage].checkout.checkout[0].extras.description}*/}
                {/*  buttonText={homepageData.modules[userLanguage].checkout.checkout[0].extras.button_title}*/}
                {/*  buttonLink={homepageData.modules[userLanguage].checkout.checkout[0].extras.button_link}*/}
                {/*  isButtonTarget={parseInt(homepageData.modules[userLanguage].checkout.checkout[0].extras.button_target, 10)}*/}
                {/*  unipayCheckoutLogo={homepageData.modules[userLanguage].checkout.checkout[0].extras.checkout_image}*/}
                {/*  paymentMethodsImg={homepageData.modules[userLanguage].checkout.checkout[0].extras.icon_image}*/}
                {/*  backImage={homepageData.modules[userLanguage].checkout.checkout[0].extras.background_image}*/}
                {/*  logosArr={homepageData.modules[userLanguage].checkout.checkout[0].extras.images}*/}
                {/*  baseUrl={baseUrl}*/}
                {/*  isVisible={!!(isCheckoutSectionVisible || isCheckoutSectionSeen)}*/}
                {/*/>*/}

                {/* <section className="premiumSection container"> */}
                {/* <Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={!!(isPremiumSectionVisible || isPremiumSectionSeen)} > */}
                {/* <SectionTitleWithText> */}
                {/* <h2> */}
                {/* {homepageData.modules[userLanguage].premium.premium[0].extras.title} */}
                {/* </h2> */}
                {/* <span dangerouslySetInnerHTML={{ __html: homepageData.modules[userLanguage].premium.premium[0].extras.description }} /> */}
                {/* </SectionTitleWithText> */}
                {/* <div className="row mt-5"> */}
                {/* <div className="col-xl-6 d-flex checklist"> */}
                {/* <ul className="my-auto list-unstyled"> */}
                {/* { */}
                {/* homepageData.modules[userLanguage].premium.premium[0].extras.titlesList.map((item, index) => ( */}
                {/* <li key={index} className="d-flex"> */}
                {/* <span> */}
                {/* <img src={`${imagesPath}/checkmark_green.svg`} style={{ width: '24px', marginRight: '8px' }} /> */}
                {/* </span> */}
                {/* <span>{item.name}</span> */}
                {/* </li> */}
                {/* )) */}
                {/* } */}
                {/* </ul> */}
                {/* </div> */}
                {/* <div className="col-xl-6"> */}
                {/* <img src={`${baseUrl}/${homepageData.modules[userLanguage].premium.premium[0].extras.image}`} className="img-fluid my-auto" /> */}
                {/* </div> */}
                {/* </div> */}
                {/* </Animated> */}
                {/* </section> */}


                {/*<FeaturesSection*/}
                {/*  title={homepageData.modules[userLanguage].features.lasFeaturesText[0].extras.title}*/}
                {/*  text={homepageData.modules[userLanguage].features.lasFeaturesText[0].extras.description}*/}
                {/*  featuresArr={homepageData.modules[userLanguage].features.lastFeaturesRepeat}*/}
                {/*  baseUrl={baseUrl}*/}
                {/*  isVisible={!!(isFeaturesSectionVisible || isFeaturesSectionSeen)}*/}
                {/*/>*/}

                {/*<TrailSection*/}
                {/*  buttonText={homepageData.modules[userLanguage].trial.trial[0].extras.button_title}*/}
                {/*  buttonLink={homepageData.modules[userLanguage].trial.trial[0].extras.button_link}*/}
                {/*  isButtonTarget={parseInt(homepageData.modules[userLanguage].trial.trial[0].extras.button_target, 10)}*/}
                {/*  text={homepageData.modules[userLanguage].trial.trial[0].extras.description}*/}
                {/*  toggleModal={toggleModal}*/}
                {/*/>*/}

                {
                  this.props.landing.footerData && (
                    <Footer
                      copyrightText={t('back.dashboard_footer_copyright')}
                      languageText={t(`back.${userLanguage === 'ka' ? 'lang_english' : 'lang_georgian'}`)}
                      languageIconName={footer.languageIconName}
                      unishopLogoName={unishopLogoName}
                      logosNamesArr={this.props.landing.footerData.icons[userLanguage].footerIcons}
                      navigationItemsArr={this.props.landing.footerData.menu[userLanguage]}
                      isOnLanding
                    />
                  )
                }
              </span>
            </ParallaxProvider>)
        }
      </I18n>
    );
  }
}

Homepage.defaultProps = {
  landing: '',
};

const mapStateToProps = ({ landing, uiInteractions }) => ({
  landing,
  contentLoading: uiInteractions.contentLoading,
  loading: uiInteractions.loading,
});

const mapDispatchToProps = dispatch => ({
  setHomepageData: bindActionCreators(actionCreators.setHomepageData, dispatch),
  setLandingFooterData: bindActionCreators(actionCreators.setLandingFooterData, dispatch),
  setLandingHeaderData: bindActionCreators(actionCreators.setLandingHeaderData, dispatch),
  toggleContentLoading: bindActionCreators(actionCreators.toggleContentLoading, dispatch),
  toggleLoading: bindActionCreators(actionCreators.toggleLoading, dispatch),
  toggleModal: bindActionCreators(actionCreators.toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
