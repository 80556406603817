// @flow
import React from 'react';

// styles
import './spinner.scss';

type Props = {
  className?: string,
  isWhite?: boolean,
};

const Spinner = ({ className, isWhite }: Props) => (
  <div className={`spinner ${className} ${isWhite ? 'white' : ''}`}>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

Spinner.defaultProps = {
  className: '',
  isWhite: false,
};

export default Spinner;
