export default {
  required: 'validation_required',
  numeric: 'validation_numeric',
  email: 'validation_email',
  minLength: 'validation_min',
  maxLength: 'validation_password_max',
  strongPassword: 'validation_strong_password',
  similarConform: 'validation_confirmed',
  whiteSpace: 'validation_without_space',
  onlyLatin: 'validation_only_lathin',
  noWhiteSpace: 'validation_without_space',
  uppercase: 'validation_password_uppercase',
  number: 'validation_only_lathin_number',
  symbol: 'validation_password_symbols',
};
