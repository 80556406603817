import { UserService } from 'SERVICES';
import { SubmissionError } from 'redux-form';

export default ({
  isDirty, formValue, toggleLoading, callCustomMessage, toggleEditForm,
}) => {
  if (isDirty) {
    const {
      number, brand_name, email,
    } = formValue;
    const objForSubmit = {
      number, brandName: brand_name, email,
    };
    toggleLoading(true);
    toggleEditForm({ formId: 'user_settings', isEditable: false });

    return UserService.updateUserCredentials(objForSubmit).then(({ data }) => {
      const { message } = data.data;
      callCustomMessage({
        id: 'user_changed_profile_info',
        isMessage: true,
        messageType: 'success',
        message,
      });
      toggleLoading(false);
    }).catch(({ data }) => {
      const { message } = data;
      const errors = {};
      let _error = '';

      if (typeof message === 'object' && message !== null) {
        const keys = Object.keys(message);
        keys.map((key) => {
          errors[key] = message[key][0];
        });
      } else {
        _error = message;
      }
      callCustomMessage({
        id: 'user_changed_profile_info',
        isMessage: false,
        messageType: 'error',
        message,
      });
      toggleLoading(false);
      throw new SubmissionError({
        ...errors,
        _error,
      });
    });
  }
};
