// @flow
import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import jsCookie from 'js-cookie';
import PropTypes from 'prop-types';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// other
import { setLastActivity, subscribtionRefreshToken } from 'FUNCTIONS';
import { rRefreshToken } from 'REQUESTS';
import { UserService } from 'SERVICES';

// components
import Modal from 'COMPONENTS/Modal';
import Button from 'COMPONENTS/Button';
import HeaderContent from './HeaderContent';

import './header.scss';

class Header extends Component {
  static async getInitialProps({
    imagesPath,
    unishopLogoName,
    unishopDarkLogoName,
    isTransparent,
    isFixed,
    className,
    style,
    headerData,
    id,
    isOnWhiteBackground,
    loading,
    customMessages,
    subscribers,
    toggleModal,
    toggleLoading,
    callCustomMessage,
    tSubscribers,
  }) {
    return {
      imagesPath,
      unishopLogoName,
      unishopDarkLogoName,
      isTransparent,
      isFixed,
      className,
      style,
      headerData,
      id,
      isOnWhiteBackground,
      loading,
      customMessages,
      subscribers,
      toggleModal,
      toggleLoading,
      callCustomMessage,
      setSubscribers,
    };
  }

  static propTypes = {
    imagesPath: PropTypes.string.isRequired,
    unishopLogoName: PropTypes.string.isRequired,
    unishopDarkLogoName: PropTypes.string.isRequired,
    isTransparent: PropTypes.bool,
    isFixed: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    headerData: PropTypes.object.isRequired,
    id: PropTypes.string,
    isOnWhiteBackground: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    customMessages: PropTypes.object.isRequired,
    subscribers: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    callCustomMessage: PropTypes.func.isRequired,
    setSubscribers: PropTypes.func.isRequired,
  };

  componentWillMount() {
    if (jsCookie.get('ACCESS_TOKEN')) {
      const { toggleModal, callCustomMessage, setSubscribers } = this.props;
      setSubscribers({
        id: 'refresh_token_subscriber',
        subscriber: subscribtionRefreshToken({ toggleModal, callCustomMessage }),
      });
    }
  }

  componentDidMount() {
    if (jsCookie.get('ACCESS_TOKEN')) {
      window.addEventListener('mousemove', setLastActivity);
      window.addEventListener('scroll', setLastActivity);
      window.addEventListener('click', setLastActivity);
    }
  }

  render() {
    const {
      isTransparent, isFixed, className, id, imagesPath, unishopLogoName, unishopDarkLogoName, style, isOnWhiteBackground, headerData, loading, customMessages, subscribers, toggleModal, toggleLoading,
    } = this.props;
    return (
      <I18n>
        {
          t => (
            <header id={id} className={`header ${className} ${isFixed ? 'fixed' : ''} ${isTransparent ? 'transparent' : ''}`} style={style}>
              <HeaderContent
                unishopLogoName={unishopLogoName}
                imagesPath={imagesPath}
                isOnWhiteBackground={isOnWhiteBackground}
                unishopDarkLogoName={unishopDarkLogoName}
                className="container-on-responsive"
                headerData={headerData}
                t={t}
                isLoading={headerData.isLoading}
                toggleModal={toggleModal}
              />
              {
                customMessages.refresh_token && customMessages.refresh_token.isMessage && (
                  <Modal
                    className="session_ends_notification"
                    modalId="session_ends_notification"
                    onCloseFunc={() => {
                      subscribers.refresh_token_subscriber.unsubscribe();
                      UserService.callProtector(true, true);
                    }}
                  >
                    <h4>
                      {customMessages.refresh_token.message}
                    </h4>
                    <Button isLoading={loading} isOutlined onClick={() => rRefreshToken(toggleModal, toggleLoading, loading)}>
                      {t('back.refresh_token_button_text')}
                    </Button>
                  </Modal>
                )
              }
            </header>
          )
        }
      </I18n>
    );
  }
}

Header.defaultProps = {
  isTransparent: false,
  isFixed: false,
  isOnWhiteBackground: false,
  className: '',
  id: '',
  style: {},
};

const mapStateToProps = ({ uiInteractions }) => {
  const { loading, customMessages, subscribers } = uiInteractions;
  return {
    loading,
    customMessages,
    subscribers,
  };
};

const mapDispatchToProps = dispatch => ({
  callCustomMessage: bindActionCreators(actionCreators.callCustomMessage, dispatch),
  toggleModal: bindActionCreators(actionCreators.toggleModal, dispatch),
  toggleLoading: bindActionCreators(actionCreators.toggleLoading, dispatch),
  setSubscribers: bindActionCreators(actionCreators.setSubscribers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
