import { AppService } from 'SERVICES';

export default (code, onSuccess, onError) => {
  if (code) {
    AppService.checkCoupon({ code }).then(({ data }) => {
      onSuccess(data);
    }).catch((error) => {
      onError(error);
    });
  }
};
