import { UserService } from 'SERVICES';

export default (formData, toggleLoading, callSuccess, callErrorMessage) => {
  toggleLoading(true);
  return UserService.newPassword(formData).then(() => {    
    toggleLoading(false);
    callSuccess();
  }).catch(({ data }) => {
    const { message } = data;
    toggleLoading(false);
    callErrorMessage(message);
  });
};
