// @flow
import React from 'react';
import { Field, SubmissionError } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import HtmlParser from 'react-html-parser';

// other
import { rRegister } from 'REQUESTS';
import env from 'CONFIG/env';

// comps
import PasswordTooltip from 'COMPONENTS/PasswordTooltip';
import { renderField, renderSelect } from 'COMPONENTS/Form';
import Button from 'COMPONENTS/Button';
import ReCAPTCHA from 'COMPONENTS/ReCAPTCHA';

type Props = {
  formData?: Object,
  imagesPath: string,
  toggleLoading: Function,
  loading: boolean,
  callSuccess: Function,
  t: Function,
  handleSubmit: Function,
  removeRecaptcha: Function,
  error: string,
  recaptchaResponse: string,
}

const { passwordMaxLength, emailMaxLength, brandNameMaxLength } = env;

const Form = ({
  formData, imagesPath, toggleLoading, loading, callSuccess, handleSubmit, error, t, recaptchaResponse, removeRecaptcha,
}: Props) => (
  <form
      autocomplete="off"
    className="form_container"
    onSubmit={handleSubmit(() => {
      if (!(formData.values && formData.values.categoryKey && formData.values.categoryKey !== '0')) {
        throw new SubmissionError({ categoryKey: 'Is Required' });
      } else {
        return rRegister(formData, toggleLoading, callSuccess, recaptchaResponse, removeRecaptcha);
      }
    })}
  >
    <h4 className="register-page-title">{t('back.auth_register_title')}</h4>
    <Field
      name="brandName"
      type="text"
      component={renderField}
      label="auth_register_brand_name"
      maxLength={brandNameMaxLength}
    />
    <div className="regGridTwo">
      <Field
        name="email"
        type="text"
        component={renderField}
        label="auth_register_email"
        maxLength={emailMaxLength}
      />
      <div style={{ position: 'relative' }}>
        <Field
            autoComplete={`off`}
            name="password"
            type="password"
            maxLength={passwordMaxLength}
            data-for="password-tooltip"
            data-tip="psw"
            data-event="focus blur"
            component={renderField}
            label="auth_register_password"
            imagesPath={imagesPath}
            identifier="register-password"
        />
        <ReactTooltip
            id="password-tooltip"
            data-event-off="blur"
            scrollHide={false}
            place="bottom"
            type="dark"
            effect="solid"
            getContent={() => <PasswordTooltip formData={formData} t={t} />}
            className="password-tooltip"
        />
      </div>
    </div>
    { renderSelect({
      name: 'categoryKey',
      label: t('back.category_title'),
      isValue: formData.values && formData.values.categoryKey && formData.values.categoryKey !== '0',
      isError: formData.submitFailed && !(formData.values && formData.values.categoryKey && formData.values.categoryKey !== '0'),
      formName: 'register',
      options: [{
        title: '',
        value: 0,
      }, {
        title: t('back.category_technic'),
        value: 'technic',
      }, {
        title: t('back.category_clothes_shoes'),
        value: 'clothes_shoes',
      }, {
        title: t('back.category_handcrafted'),
        value: 'handcrafted',
      }, {
        title: t('back.category_toys'),
        value: 'toys',
      }, {
        title: t('back.category_accessories'),
        value: 'accessories',
      }, {
        title: t('back.category_books'),
        value: 'books',
      }, {
        title: t('back.category_services_quotes'),
        value: 'services_quotes',
      }, {
        title: t('back.category_furniture'),
        value: 'furniture',
      }, {
        title: t('back.category_fitness'),
        value: 'fitness',
      }, {
        title: t('back.category_sport'),
        value: 'sport',
      }, {
        title: t('back.category_other'),
        value: 'other',
      }],
    })
    }
    <div className="phone_number_inputs">
      <Field name="country_code" className="select_input" disabled component="select">
        <option value="+995">+995</option>
      </Field>
      <Field
        name="number"
        type="tel"
        component={renderField}
        label="auth_register_number"
      />
    </div>
    {
      error && <p className="main_error_message">{error}</p>
    }
    <ReCAPTCHA />
    <p className="terms_n_privacy_section">
      <span className="terms_n_privacy_text">
        {

          HtmlParser(t('back.auth_register_privacy'))
        }
      </span>
    </p>
    <div className="centered_button">
      <Button
        className="bgBlueButton"
        type="submit"
        isLoading={loading}
      >
        {t('back.auth_register_button')}
      </Button>
    </div>
  </form>
);

Form.defaultProps = {
  formData: {},
};

export default Form;
