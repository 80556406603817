export default {
  LOADING: 'LOADING',
  CONTENT_LOADING: 'CONTENT_LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  GET_DASHBOARD_LAYOUT_DATA: 'GET_DASHBOARD_LAYOUT_DATA',
  TOGGLE_DROPDOWN: 'TOGGLE_USER_DROPDOWN',
  CALL_CUSTOM_MESSAGE: 'CALL_CUSTOM_MESSAGE',
  TOGGLE_NOTIFICATION_SEEN: 'TOGGLE_NOTIFICATION_SEEN',
  GET_UPGRADE_PRICES: 'GET_UPGRADE_PRICES',
  GET_ORDERS: 'GET_ORDERS',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  TOGGLE_BURGER: 'TOGGLE_BURGER',
  SET_HOMEPAGE_DATA: 'SET_HOMEPAGE_DATA',
  SET_LANDING_FOOTER_DATA: 'SET_LANDING_FOOTER_DATA',
  SET_DEFAULT_LAYOUT_DATA: 'SET_DEFAULT_LAYOUT_DATA',
  SET_CONTACT_PAGE_DATA: 'SET_CONTACT_PAGE_DATA',
  SET_SUBSCRIBERS: 'SET_SUBSCRIBERS',
  SET_RECAPTCHA: 'SET_RECAPTCHA',
  REMOVE_RECAPTCHA: 'REMOVE_RECAPTCHA',
  SET_LANDING_HEADER_DATA: 'SET_LANDING_HEADER_DATA',
  SET_COOKIE_DATA: 'SET_COOKIE_DATA',
  TOGGLE_EDIT_FORM: 'TOGGLE_EDIT_FORM',
  SWITCH_PAGE: 'SWITCH_PAGE',
  TOGGLE_CUSTOM_LOADER: 'TOGGLE_CUSTOM_LOADER',
};
