// @flow
import { LandingService } from 'SERVICES';
import { SubmissionError } from 'redux-form';

type Args = {
  formData: Object,
  toggleLoading: Function,
  callCustomMessage: Function,
  removeRecaptcha: Function,
  destroy: Function,
  dispatch: Function,
  recaptchaResponse: string,
  loading: boolean,
}

export default ({
  formData, toggleLoading, callCustomMessage, removeRecaptcha, recaptchaResponse, loading, destroy, dispatch,
}: Args) => {
  if (loading) {
    return;
  }
  const objForSubmit = {
    ...formData.values,
    recaptcha_response: recaptchaResponse,
  };
  toggleLoading(true);
  return LandingService.sendEmail(objForSubmit).then(({ data }) => {
    callCustomMessage({
      id: 'email_send',
      isMessage: true,
      message: data.data[0],
      messageType: 'success',
    });
    toggleLoading(false);
    removeRecaptcha();
    dispatch(destroy('contact'));
    window.grecaptcha.reset();
  }).catch(({ data }) => {
    const { message } = data;
    const errors = {};
    let _error = '';

    toggleLoading(false);
    window.grecaptcha.reset();

    if (typeof message === 'object' && message !== null) {
      const keys = Object.keys(message);
      keys.map((key) => {
        errors[key] = message[key][0];
      });
    } else {
      _error = message;
    }

    throw new SubmissionError({
      ...errors,
      _error,
    });
  });
};
