// @flow
import React from 'react';

// other
import { Link } from 'ROUTES';
import { changeLanguage } from 'FUNCTIONS';
import env from 'CONFIG/env';

// components
import Register from 'PAGES/auth/register';
import Login from 'PAGES/auth/login';
import ResetPassword from 'PAGES/auth/resetPassword';
import Modal from 'COMPONENTS/Modal';
import Navigation from './Navigation';

// styles
import './footer.scss';

const { baseUrl, imagesPath } = env;

type Props = {
  copyrightText: string,
  languageText: string,
  logosNamesArr: Array,
  unishopLogoName: string,
  languageIconName: string,
  navigationItemsArr: Array,
  className?: string,
  id?: string,
  style?: Object,
  isOnLanding: boolean,
}

const Footer = ({
  copyrightText, languageText, logosNamesArr, unishopLogoName, languageIconName, navigationItemsArr, className, id, style, isOnLanding,
}: Props) => (
  <footer className={`footer ${className}`} id={id} style={style}>
    <p>E Commerce Georgia</p>
  </footer>
);

Footer.defaultProps = {
  className: '',
  id: '',
  style: {},
};

export default Footer;
