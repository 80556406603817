const routes = require('next-routes');

module.exports = routes()
  .add('Homepage', '/', 'auth/login')
  .add('Error', '/error', 'customErrors')
  .add('App', '/dashboard', 'app')
  .add('Profile', '/dashboard/profile', 'app')
  .add('AppOtherPages', '/dashboard/:pageName', 'app')
  .add('AppOtherPagesWithPagination', '/dashboard/:pageName/:pageNumber', 'app')
  .add('Register', '/register', 'auth/register')
  .add('Login', '/signin', 'auth/login')
  .add('ResetPassword', '/reset', 'auth/resetPassword')
  .add('NewPassword', '/new-password/:token', 'auth/newPassword')
  .add('ActivateAccount', '/activate/:token', 'auth/activateAccount')
  .add('UnblockUser', '/unblocked-user/:token', 'auth/unblockUser')
  .add('CheckoutStatus', '/checkout/:status', 'checkoutStatus')
  .add('LandingDefaultPage', '/page/:slag', 'landing/defaultPage')
  .add('Contact', '/contact', 'landing/contact')
  .add('ErrorPage', '/error/:title', 'landing/error')
  .add('Suspend', '/store-front-suspend', 'landing/suspend');
