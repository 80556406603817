// @flow
import React from 'react';
import { Animated } from 'react-animated-css';

import Button from 'COMPONENTS/Button';

// other
import { pixel } from 'CONFIG';

// style
import './checkoutSection.scss';

type Props = {
  title: string,
  text: string,
  buttonText: string,
  buttonLink: string,
  isButtonTarget: boolean,
  isVisible?: boolean | number,
  unipayCheckoutLogo: string,
  backImage: string,
  logosArr: Array,
  baseUrl: string,
  className?: string,
  id?: string,
  style?: Object,
}

const { trackers, leadKeys } = pixel;

const CheckoutSection = ({
  title, text, buttonText, buttonLink, isButtonTarget, unipayCheckoutLogo, baseUrl, className, id, style, backImage, isVisible, logosArr,
}: Props) => (
  <section className={`checkoutSection ${className}`} id={id} style={{ ...style, backgroundImage: backImage ? `url(${baseUrl}/${backImage})` : 'none' }}>
    {/*<div className="background">*/}
    {/*  <Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={isVisible} className="left">*/}
    {/*    <img src={`${baseUrl}/${unipayCheckoutLogo}`} />*/}
    {/*    /!* <img src={`${baseUrl}/${paymentMethodsImg}`} /> *!/*/}
    {/*  </Animated>*/}
    {/*  <Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={isVisible} className="right">*/}
    {/*    <h3>{title}</h3>*/}
    {/*    <p>{ text }</p>*/}
    {/*    /!* <p dangerouslySetInnerHTML={{ __html: text }} /> *!/*/}
    {/*    <div className="checkout-logos-n-btn">*/}
    {/*      <div className="checkout-logos">*/}
    {/*        {*/}
    {/*          logosArr.map((imgSrc, i) => imgSrc && <img className="checkout-type-logo" key={imgSrc + i} src={`${baseUrl}/${imgSrc}`} />)*/}
    {/*        }*/}
    {/*      </div>*/}
    {/*      <a href={buttonLink} target={isButtonTarget ? '_blank' : '_self'}>*/}
    {/*        <Button onClick={() => { trackers.leadCustom(leadKeys.UNIPAY_CHECKOUT, { source: 'home' }); }} isOutlined>{buttonText}</Button>*/}
    {/*      </a>*/}
    {/*    </div>*/}
    {/*  </Animated>*/}
    {/*</div>*/}
  </section>
);

CheckoutSection.defaultProps = {
  className: '',
  id: '',
  style: {},
  isVisible: false,
};

export default CheckoutSection;
