// @flow
import { LandingService } from 'SERVICES';

type Args = {
  setDefaultLayoutData: Function,
  toggleContentLoading: Function,
  requestUrl: string,
}

export default ({ setDefaultLayoutData, toggleContentLoading, requestUrl }: Args) => {
  LandingService.getDefaultPageData(requestUrl).then(({ data }) => {
    setDefaultLayoutData(data.data);
    toggleContentLoading(false);
  }).catch((error) => {
    window.location = '/404';
    console.log(error);
  });
};
