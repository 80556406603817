import { t } from 'i18next';
import { timer } from 'rxjs';
import { timestamp as rxTimestamp } from 'rxjs/operators';
import jsCookie from 'js-cookie';

const source = timer(0, 10000).pipe(rxTimestamp());

const subscribtionRefreshToken = ({ toggleModal, callCustomMessage }) => source.subscribe(({ timestamp }) => {
  const notifyTime = parseInt(jsCookie.get('NOTIFY_USER_ABOUT_SESSION_END_TIME'), 10);
  if (timestamp >= notifyTime) {
    callCustomMessage({
      id: 'refresh_token',
      messageType: 'warning',
      isMessage: true,
      message: t('back.refresh_token_note'),
    });
    toggleModal({ modalId: 'session_ends_notification', isOpen: true });
  }
});

export default subscribtionRefreshToken;
