import posed from 'react-pose';

import transition from './transition';

export default posed.div({
  hidden: { opacity: 0, marginBottom: '-100px' },
  visible: {
    opacity: 1,
    marginBottom: '0px',
    transition,
  },
});
