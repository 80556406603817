// @flow
import React from 'react';
import { I18n } from 'react-i18next';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// other
import { Link } from 'ROOT/routes';
import env from 'CONFIG/env';

type Props = {
  dropdowns: Object,
  email: string,
  storeId: string,
  logOutRequest: Function,
  toggleLoading: Function,
  toggleDrowdown: Function,
  onLanding: boolean,
}

const { imagesPath } = env;

const UserDropdown = ({
  dropdowns, email, storeId, logOutRequest, toggleLoading, toggleDrowdown, onLanding,
}: Props) => (
  <I18n>
    {
      t => (
        <div
          className={`user_dropdown ${onLanding ? 'dropdown_on_landing' : ''}`}
          style={{ display: dropdowns.user_dropdown && dropdowns.user_dropdown.isOpen ? 'block' : 'none' }}
        >
          <header>
            <p>{email}</p>
            <p>
              {t('back.dashboard_store_id')}
              {' '}
              { storeId }
            </p>
          </header>
          <nav>
            <Link route="App">
              <ul>
                <img src={`${imagesPath}/dashboard_24x24_icon.svg`} />
                {t('back.back_layout_dashboard')}
              </ul>
            </Link>
            <Link route="Profile">
              <ul>
                <img src={`${imagesPath}/settings_24x24_icon.svg`} />
                {t('back.profile_settings_title')}
              </ul>
            </Link>
            <ul onClick={() => logOutRequest(toggleLoading, toggleDrowdown)}>
              <img src={`${imagesPath}/logout_24x24_icon_gray.svg`} />
              {t('back.profile_log_out')}
            </ul>
          </nav>
        </div>
      )
    }
  </I18n>
);

const mapStateToProps = ({ uiInteractions }) => {
  const { dropdowns } = uiInteractions;
  return { dropdowns };
};

const mapDispatchToProps = dispatch => ({
  toggleLoading: bindActionCreators(actionCreators.toggleLoading, dispatch),
  toggleDrowdown: bindActionCreators(actionCreators.toggleDrowdown, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);
