// @flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18next';

// components
import ErrorPageContainer from 'COMPONENTS/ErrorPageContainer';
import Button from 'COMPONENTS/Button';

class _error extends PureComponent {
  static async getInitialProps({ res, err }) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    return { statusCode };
  }

  static propTypes = {
    statusCode: PropTypes.number.isRequired,
  };

  render() {
    const { statusCode } = this.props;

    return (
      <ErrorPageContainer className="default-errors">
        <I18n>
          {
            t => (
              <>
                <h3>{t('back.error_page_title')}</h3>
                <p>
                  {t('back.error_page_status_text')}
                  { ' ' }
                  { statusCode }
                </p>
                <a href="/">
                  <Button isPrimary>
                    {t('back.error_page_btn_text')}
                  </Button>
                </a>
              </>
            )
          }
        </I18n>
      </ErrorPageContainer>
    );
  }
}

export default _error;
