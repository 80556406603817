import { UserService } from 'SERVICES';
import env from 'CONFIG/env';

const { ACCESS_TOKEN } = env;

// landing header data with user and store info
export default ({ setLandingHeaderData }) => {
  if (UserService.getSession(ACCESS_TOKEN)) {
    UserService.getUserNStore().then(({ data }) => {
      setLandingHeaderData({
        ...data.data,
        isAuthorized: true,
        isLoading: false,
      });
    }).catch((err) => {
      setLandingHeaderData({
        isAuthorized: false,
        isLoading: false,
      });
      if (err.data.message === 'Unauthenticated.') {
        UserService.callProtector(true, true);
      }
      console.log(err);
    });
  } else {
    setLandingHeaderData({
      isLoading: false,
    });
  }
};
