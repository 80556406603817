// @flow
import React from 'react';

// components
import {BurgerNav, BurgerOpenIcon} from 'COMPONENTS/BurgerNav';
import {LoadableComponent} from 'HOCS';
import Button from 'COMPONENTS/Button';
import UserMenu from 'COMPONENTS/UserMenu';
import {Link} from 'ROUTES';
// other
import {pixel} from 'CONFIG';

const {trackers, registerKeys} = pixel;

type
Props = {
    imagesPath: string,
    className? : string,
    unishopLogoName: string,
    unishopDarkLogoName: string,
    headerData: Object,
    isOnWhiteBackground: boolean,
    t: Function,
    toggleModal: Function,
}

const HeaderContent = ({
                           imagesPath, unishopLogoName, unishopDarkLogoName, isOnWhiteBackground, className, headerData, t, toggleModal,
                       }: Props) => (
    <div className={`header_content ${className}`}>
        <a href="/">
            <img src={`${imagesPath}/${isOnWhiteBackground ? unishopDarkLogoName : unishopLogoName}`} className="logo"/>
        </a>
        {
            headerData.isAuthorized ? (
                <UserMenu onLanding userData={headerData.user} storeId={headerData.store.store_id}
                          brandName={headerData.store.brand_name} isOnWhiteBackground={isOnWhiteBackground}/>
            ) : (
                <div className="navigation">
                    <Link route="Login">
                        {t('back.sign_in')}
                    </Link>
                    <Link route="Register">
                        {t('back.register')}
                    </Link>
                </div>
            )
        }
        {!headerData.isAuthorized && <BurgerOpenIcon isOnWhiteBackground={isOnWhiteBackground}/>}
        {!headerData.isAuthorized && (
            <BurgerNav isFullWidth>
                <a href="/" className="logo-in-burger">
                    <img src={`${imagesPath}/${unishopDarkLogoName}`}/>
                </a>
                <div className="burger-buttons">
                    <Button isOutlined>
                        <Link route="Login">
                            {t('back.sign_in')}
                        </Link>
                    </Button>
                    <br/>
                    <Button isOutlined>
                        <Link route="Register">
                            {t('back.register')}
                        </Link>
                    </Button>
                </div>
            </BurgerNav>
        )}
    </div>
);

HeaderContent.defaultProps = {
    className: '',
};

export default LoadableComponent(HeaderContent, () => null);
