import jsCookie from 'js-cookie';
import { checkAndSetUserLanguage } from 'FUNCTIONS';

export default (event, USER_LANGUAGE = 'USER_LANGUAGE') => {
  event.preventDefault();

  switch (checkAndSetUserLanguage()) {
    case 'ka':
      jsCookie.set(USER_LANGUAGE, 'en');
      break;

    case 'en':
      jsCookie.set(USER_LANGUAGE, 'ka');
      break;

    default:
      jsCookie.set(USER_LANGUAGE, 'ka');
  }

  window.location.reload();
};
