// @flow
import { LandingService } from 'SERVICES';
import { actionTypes } from 'ACTIONS';

type Args = {
  setHomepageData: Function,
  toggleContentLoading: Function,
}

export default ({ dispatch }: Args) => async () => {
  await LandingService.getHomepageData().then(({ data }) => {

    dispatch({
      type: actionTypes.SET_HOMEPAGE_DATA,
      data: data.data,
    });
    dispatch({
      type: actionTypes.CONTENT_LOADING,
      isLoading: false,
    });
  }).catch((error) => {
    console.log(error);
  });
};
