// @flow
import { UserService } from 'SERVICES';
import { SubmissionError } from 'redux-form';


export default ({
  isDirty, formValue, toggleLoading, callCustomMessage, toggleEditForm,
}) => {
  if (isDirty) {
    const { old_password, password, password_confirmation } = formValue;
    toggleLoading(true);
    toggleEditForm({ formId: 'user_password', isEditable: false });

    return UserService.changePassword({
      old_password,
      password,
      password_confirmation,
    }).then(({ data }) => {
      const { message } = data.data;
      callCustomMessage({
        id: 'changed_password_message',
        isMessage: true,
        messageType: 'success',
        message,
      });
      toggleLoading(false);
    }).catch(({ data }) => {
      const { message } = data;
      const errors = {};
      let _error = '';

      if (typeof message === 'object' && message !== null) {
        const keys = Object.keys(message);
        keys.map((key) => {
          errors[key] = message[key][0];
        });
      } else {
        _error = message;
      }
      callCustomMessage({
        id: 'changed_password_message',
        isMessage: false,
        messageType: 'error',
        message,
      });
      toggleLoading(false);
      throw new SubmissionError({
        ...errors,
        _error,
      });
    });
  }
};
