import { UserService } from 'SERVICES';

// func :: obj, func, func => bool || promise
export default (token, toggleLoading, callErrorMessage) => {
  toggleLoading(true);
  return UserService.unblockUser(token).then(() => {
    toggleLoading(false);
    window.location.assign('/?unblock_account=true');
  }).catch(({ data }) => {
    const { message } = data;
    callErrorMessage(message);
    toggleLoading(false);
  });
};
