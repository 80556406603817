// @flow
import React from 'react';
import { I18n } from 'react-i18next';

import './inputStyles.scss';

const renderField = ({
  input,
  label,
  type,
  isHidden,
  isDisabled,
  placeholder,
  defaultValue,
  imagesPath,
  className,
  autoFocus,
  maxLength,
  identifier,
  meta: {
    touched, error, dirty, active, invalid, ...rest
  },
  ...args
}) => {
  let passwordInput = null;
  const changeableType = passwordInput ? passwordInput.type : type;
  const eyeIcon = {
    show: 'eye_opened_24x24_icon.svg',
    hide: 'eye_closed_24x24_icon.svg',
  };
  const iconSrc = `${imagesPath}/${eyeIcon.hide}`;

  const toggleShowPassword = (id) => {
    passwordInput = document.getElementById(`input_${id}_${identifier}`);
    const togglePasswordIcon = document.getElementById(`icon_${id}_${identifier}`);
    if (isDisabled) {
      return;
    }
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
      togglePasswordIcon.src = `${imagesPath}/${eyeIcon.show}`;
    } else {
      passwordInput.type = 'password';
      togglePasswordIcon.src = `${imagesPath}/${eyeIcon.hide}`;
    }
  };

  return (
    <I18n>
      {
        t => (
          <div className={`form_input ${className || ''}`}>
            { !isHidden && <label className={`label ${dirty ? 'show_label' : ''}`}>{t(`back.${label}`)}</label> }
            <div className="input_div">
              {
                imagesPath && (
                  <img
                    src={iconSrc}
                    id={`icon_${input.name}_${identifier}`}
                    className={`icon toggle_password ${isDisabled ? 'disabled-input-icon' : ''}`}
                    onClick={() => toggleShowPassword(input.name)}
                  />
                )
              }
              {
                isHidden ? (
                  <input {...input} className="hidden" id="hidden_input_field" onChange={input.onChange(defaultValue)} />
                ) : (
                  <input
                    id={`input_${input.name}_${identifier}`}
                    {...input}
                    {...args}
                    placeholder={t(`back.${placeholder || label}`)}
                    type={changeableType}
                    className={`input ${(error && rest.visited && dirty) || (rest.submitFailed && error) ? 'error' : 'valid-input'} ${imagesPath ? 'password_input' : ''}`}
                    disabled={isDisabled}
                    maxLength={maxLength || 'auto'}
                    autoFocus={autoFocus}
                  />
                )
              }
              <span className={`feedback ${touched && error && rest.visited && !isHidden ? 'show' : ''}`}>
                {error && rest.submitFailed ? error : ''}
              </span>
            </div>
          </div>
        )
      }
    </I18n>
  );
};

export default renderField;
