// @flow
import React from 'react';
import { Animated } from 'react-animated-css';

// components
import SectionTitleWithText from 'COMPONENTS/SectionTitleWithText';
import Feature from './Feature';

// style
import './featuresSection.scss';

type Props = {
  title: string,
  text: string,
  featuresArr: Array,
  baseUrl: string,
  isVisible?: boolean | number,
  className?: string,
  id?: string,
  style?: Object,
}

const FeaturesSection = ({
  title, text, featuresArr, baseUrl, className, id, style, isVisible,
}: Props) => (
  <section className={`featuresSection ${className}`} id={id} style={style}>
    {/*<Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={isVisible}>*/}
    {/*  <SectionTitleWithText>*/}
    {/*    <h2>{title}</h2>*/}
    {/*    <span dangerouslySetInnerHTML={{ __html: text }} />*/}
    {/*  </SectionTitleWithText>*/}
    {/*</Animated>*/}
    {/*<div className="features">*/}
    {/*  {*/}
    {/*    featuresArr.map((item, index) => (*/}
    {/*      <Feature isVisible={isVisible} key={index + item.extras.image}>*/}
    {/*        <img src={`${baseUrl}/${item.extras.image}`} />*/}
    {/*        <p>{item.extras.title}</p>*/}
    {/*      </Feature>*/}
    {/*    ))*/}
    {/*  }*/}
    {/*</div>*/}
  </section>
);

FeaturesSection.defaultProps = {
  className: '',
  id: '',
  style: {},
  isVisible: false,
};

export default FeaturesSection;
