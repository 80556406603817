// @flow
import React from 'react';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';


// other
import env from 'CONFIG/env';

const { imagesPath } = env;

type Props = {
  toggleBurgerNav: Function,
  isOnWhiteBackground?: boolean,
}

const BurgerOpenIcon = ({ toggleBurgerNav, isOnWhiteBackground }: Props) => (
  <img
    onClick={() => toggleBurgerNav(true)}
    className="burger-icon"
    src={`${imagesPath}/${isOnWhiteBackground ? 'hamburger_menu_24x24_black' : 'hamburger_menu_24x24'}.svg`}
  />
);

BurgerOpenIcon.defaultProps = {
  isOnWhiteBackground: false,
};

const mapDispatchToProps = dispatch => ({
  toggleBurgerNav: bindActionCreators(actionCreators.toggleBurgerNav, dispatch),
});

export default connect(null, mapDispatchToProps)(BurgerOpenIcon);
