// @flow
import { UserService } from 'SERVICES';
import { SubmissionError } from 'redux-form';
import Router from 'next/router';

// func :: obj, func, func, str, func => void
export default (formData, toggleLoading, callCustomMessage) => {
  toggleLoading(true);

  return UserService.login(formData).then(({ data }) => {
    const { token } = data.data;
    toggleLoading(false);
    UserService.setUser(token);
    callCustomMessage({
      id: 'refresh_token',
      isMessage: false,
    });
    Router.push('/dashboard');
  }).catch(({ data }) => {
    const { message } = data;
    const errors = {};
    let _error = '';

    toggleLoading(false);
    callCustomMessage({
      id: 'refresh_token',
      isMessage: false,
    });

    if (typeof message === 'object' && message !== null) {
      const keys = Object.keys(message);
      keys.map((key) => {
        errors[key] = message[key][0];
      });
    } else {
      _error = message;
    }

    throw new SubmissionError({
      ...errors,
      _error,
    });
  });
};
