// @flow
import React from 'react';
import { Field } from 'redux-form';

import env from 'CONFIG/env';

import './inputStyles.scss';

const { imagesPath } = env;

type Props = {
  label: string,
  name: string,
  options: Array,
  isValue: boolean,
  isError: boolean,
}

const toggleDropDownArrow = (iconClass) => {
  if (document.getElementsByClassName(iconClass)[0].classList.value.includes('select-open')) {
    document.getElementsByClassName(iconClass)[0].classList.remove('select-open');
  } else {
    document.getElementsByClassName(iconClass)[0].classList.add('select-open');
  }
};

const RenderField = ({
  label, name, options, isValue, isError,
}: Props) => (
  <div className="select-field">
    <label className={`label ${isValue ? 'selected' : ''}`}>{label}</label>
    <img className={`arrow-icon select-arrow-icon-${name.replace(' ', '')}`} src={`${imagesPath}/backarrow_18x18_icon_black.svg`} alt="" />
    <Field
        style={{background: 'none'}}
      name={name}
      component="select"
      className={`${isError ? 'error' : ''} select-input-${name.replace(' ', '')}`}
      // onFocus={() => document.getElementsByClassName(`select-arrow-icon-${name.replace(' ', '')}`)[0].classList.add('select-open')}
      // onChange={() => document.getElementsByClassName(`select-input-${name.replace(' ', '')}`)[0].blur()}
      onBlur={() => document.getElementsByClassName(`select-arrow-icon-${name.replace(' ', '')}`)[0].classList.remove('select-open')}
      onClick={() => toggleDropDownArrow(`select-arrow-icon-${name.replace(' ', '')}`)}
    >
      {
        options.map(option => <option key={option.value} value={option.value}>{option.title}</option>)
      }
    </Field>
  </div>
);

export default RenderField;
