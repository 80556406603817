// @flow
import { LandingService } from 'SERVICES';

type Args = {
  setContactPageData: Function,
  toggleContentLoading: Function,
};

export default ({ setContactPageData, toggleContentLoading }: Args) => {
  LandingService.getContactLayoutData().then(({ data }) => {
    setContactPageData(data.data.contact);
    toggleContentLoading(false);
  }).catch((error) => {
    console.log(error);
  });
};
