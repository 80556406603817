const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = ' ';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = ' ';
  } else if (values.email.split('@')[0].length > 64) {
    errors.email = ' ';
  } else if (values.email.split('@')[1].length > 250) {
    errors.email = ' ';
  }

  return errors;
};

export default validate;
