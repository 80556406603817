// @flow
import React from 'react';
import { I18n } from 'react-i18next';

import './inputStyles.scss';

const renderTextarea = ({
  input,
  label,
  isDisabled,
  placeholder,
  imagesPath,
  className,
  meta: {
    touched, error, dirty, active, invalid, ...rest
  },
}) => (
  <I18n>
    {
      t => (
        <div className={`form_input text-area ${className || ''}`}>
          <label className={`label ${dirty ? 'show_label' : ''}`}>{t(`back.${label}`)}</label>
          <div className="input_div">
            <textarea
              id={`input_${input.name}`}
              {...input}
              placeholder={t(`back.${placeholder || label}`)}
              className={`input ${(error && rest.visited && dirty) || (rest.submitFailed && error) ? 'error' : 'valid-input'} ${imagesPath ? 'password_input' : ''}`}
              disabled={isDisabled}
            />
            <span className={`feedback ${error && rest.visited && dirty ? 'show' : ''}`}>
              {error && rest.submitFailed ? error : ''}
            </span>
          </div>
        </div>
      )
    }
  </I18n>
);

export default renderTextarea;
