import jsCookie from 'js-cookie';
import env from 'CONFIG/env';
import Router from 'next/router';
import querystring from 'querystring';
import HttpService from './HttpService';

const {
  REFRESH_TOKEN, ACCESS_TOKEN, EXPIRES_TOKEN_IN, LOGIN_TIME, REFRESH_TOKEN_MUST_SEND_TIME, NOTIFY_USER_ABOUT_SESSION_END_TIME, apiUrl, AUTO_LOGOUT_TIME, USER_LAST_ACTIVITY,
} = env;

class UserService extends HttpService {
  // register :: (object) => promise
  static register(data) {
    return this.post('auth/signup', data);
  }

  // login :: (object) => promise
  static login(data) {
    return this.post('auth/login', data);
  }

  // resetPassword :: (object) => promise
  static resetPassword(data) {
    return this.post('auth/password/create', data);
  }

  // newPassword :: (object) => promise
  static newPassword(data) {
    return this.post('auth/password/reset', data);
  }

  // checkNewPasswordToken :: (string) => promise
  static checkNewPasswordToken(token) {
    return this.get(`auth/password/find/${token}`);
  }

  // activateAccount :: (string) => promise
  static activateAccount(token) {
    return this.get(`/auth/signup/activate/${token}`);
  }

  // activateAccount :: (string) => promise
  static unblockUser(token) {
    return this.get(`/auth/user/unblocked/${token}`);
  }

  // updateUserCredentials :: object => promise
  static updateUserCredentials(data) {
    return this.post('auth/setting/change-credentials', data, this.generateBearer());
  }

  // updateUserCredentials :: object => promise
  static changePassword(data) {
    return this.post('auth/setting/change-password', data, this.generateBearer());
  }

  // logOut :: () => promise
  static logOut() {
    return this.get('auth/logout', this.generateBearer());
  }

  // refreshToken :: () => promise
  static refreshToken() {
    const token = this.getSession(REFRESH_TOKEN);
    return this.post('/auth/user/refreshtoken', { refresh_token: token });
  }

  // notificationSeen :: (object) => promise
  static notificationSeen(data) {
    return this.post('/auth/notification/seen', data, this.generateBearer());
  }

  // upgradeCheckout :: (object) => promise
  static upgradeCheckout(data) {
    return this.post('/auth/pay', data, this.generateBearer());
  }

  // getOrders :: (obj) => promise
  static getOrders(data) {
    return this.post('/auth/orders', data, this.generateBearer());
  }

  // getUserNStore :: () => promise
  static getUserNStore() {
    return this.get('auth/user/store', this.generateBearer());
  }

  // getPdf :: (string | number) => string
  static getPdf(orderId) {
    return `${apiUrl}/auth/orders/getpdf?${querystring.stringify({ orderId, token: this.getSession(ACCESS_TOKEN) })}`;
  }

  static isTimeToNotifyUserAboutRefreshToken() {
    return this.getSession(NOTIFY_USER_ABOUT_SESSION_END_TIME) <= Date.now();
  }

  // generateBearer :: () => obj
  static generateBearer() {
    const token = this.getSession(ACCESS_TOKEN);
    return { Authorization: `Bearer ${token}` };
  }

  // setSession :: (string, a) => void
  static setSession(key, value) {
    jsCookie.set(key, value);
  }

  // getSession :: (string) => string
  static getSession(key) {
    return jsCookie.get(key);
  }

  // clearSession :: () => void
  static clearUserSession() {
    jsCookie.remove(ACCESS_TOKEN);
    jsCookie.remove(REFRESH_TOKEN);
    jsCookie.remove(EXPIRES_TOKEN_IN);
    jsCookie.remove(LOGIN_TIME);
    jsCookie.remove(REFRESH_TOKEN_MUST_SEND_TIME);
    jsCookie.remove(NOTIFY_USER_ABOUT_SESSION_END_TIME);
    jsCookie.remove(AUTO_LOGOUT_TIME);
    jsCookie.remove(USER_LAST_ACTIVITY);
  }

  // setUser :: obj => void
  static setUser(token) {
    this.setSession(ACCESS_TOKEN, token.access_token);
    this.setSession(REFRESH_TOKEN, token.refresh_token);
    this.setSession(EXPIRES_TOKEN_IN, token.expires_in);
    this.setSession(LOGIN_TIME, Date.now());
    this.setSession(REFRESH_TOKEN_MUST_SEND_TIME, Date.now() + (token.expires_in * 1000));
    this.setSession(NOTIFY_USER_ABOUT_SESSION_END_TIME, Date.now() + (token.expires_in * 1000) - (2 * 60000));
    this.setSession(AUTO_LOGOUT_TIME, Date.now() + (token.expires_in * 1000));
  }

  static deleteAccount() {
    return this.post('auth/delete-account', {}, this.generateBearer());
  }

  // callProtector :: (bool) => void
  static callProtector(needProtection, dontNeedRedirect, dontNeedQuery) {
    const accessToken = this.getSession(ACCESS_TOKEN);
    const refreshToken = this.getSession(REFRESH_TOKEN);
    if (needProtection || !accessToken || !refreshToken) {
      this.clearUserSession();
      if (!dontNeedRedirect) {
        if (!dontNeedQuery) {
          window.location.assign('/?session_ended=true');
        } else {
          window.location.assign('/');
        }
      } else {
        window.location.reload();
      }
    }
  }
}

export default UserService;
