// @flow
import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';

// redux
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// components
import Button from 'COMPONENTS/Button';
import { renderField } from 'COMPONENTS/Form';
import { Link } from 'ROOT/routes';
import SuccessMessage from 'COMPONENTS/Message';

// styles
import 'bootstrap/scss/bootstrap.scss';
import 'STYLES/main.scss';
import './resetPassword.scss';

// other
import { resetValidation } from 'VALIDATION';
import env from 'CONFIG/env';
import { rResetPassword } from 'REQUESTS';

// data

const { emailMaxLength, imagesPath, userLanguage } = env;

class ResetPassword extends Component {
  static async getInitialProps({
    handleSubmit, formData, loading, toggleLoading, valid, error,
  }) {
    return {
      handleSubmit, formData, loading, toggleLoading, valid, error,
    };
  }

  state = {
    isSuccess: false,
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    callSuccess: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  render() {
    const {
      handleSubmit, formData, loading, toggleLoading, error,
    } = this.props;
    const { isSuccess } = this.state;

    return (
      <NoSSR>
        <I18n>
          {
          t => (
            <div className="reset_password_component">
              {
                  isSuccess ? (
                      <SuccessMessage
                      className="CardWhite form_container"
                      title={t('back.reset_password_email_was_send_title')}
                      text={<p>
                      <p>{t('back.reset_password_email_was_send_text')}</p>
                      <p className="login_section1">
                      <Button
                      className="bgBlueButton">
                      <Link route="Login">
                      {t('back.sign_in')}
                      </Link></Button>
                      </p></p>}
                      />
                  ) : (
                      <div className="CardWhite ResetGrid">
                        <div className="ResetImage">
                          <a href="https://unishop.ge">
                            <img src={`${imagesPath}/unishop_logo_black.svg`} className="logo positionAbsolute" />
                          </a>
                          <img src={`${imagesPath}/auth/Reset.svg`} />
                        </div>
                        <div className="ResetFormSection">
			            <a href="https://unishop.ge">
                            <img src={`${imagesPath}/unishop_logo_black.svg`} className="logo positionAbsolute1" />
                          </a>
                          <span className="form_n_login_redirect_container">
                            <form
                              className="form_container"
                              onSubmit={handleSubmit(() => rResetPassword(formData, toggleLoading, () => this.setState({ isSuccess: true })))}
                            >
                              <h4 className="reset-password-modal-title">{t('back.auth_reset_title')}</h4>
                              <Field
                                name="email"
                                type="text"
                                component={renderField}
                                label="auth_reset_email"
                                maxLength={emailMaxLength}
                              />
                              {
                            error && <p className="main_error_message">{ error }</p>
                          }
                              <div className="centered_button">
                                <Button className="bgBlueButton" isLoading={loading} type={!loading ? 'submit' : 'button'}>{t('back.auth_reset_button')}</Button>
                              </div>
                            </form>
                          </span>
                          <p className="back_section">
                            <Link route="Login">
                              {t('back.goBack')}
                            </Link>
                          </p>
                          {/*<p className="beforeAfterSection">*/}
                          {/*  <span>{t('back.or_connect_using')} </span>*/}
                          {/*</p>*/}
                          {/*<div className="Soc_Section">*/}
                          {/*  <Button className="socButton FbBlue">*/}
                          {/*    <img className="IconSOC F" src={`${imagesPath}/FacebookL.svg`} />*/}
                          {/*    <img className="IconSOC F1" src={`${imagesPath}/whiteF.svg`} />*/}
                          {/*  </Button>*/}
                          {/*  <Button className="socButton GRed">*/}
                          {/*    <img className="IconSOC G" src={`${imagesPath}/google.png`} />*/}
                          {/*    <img className="IconSOC G1" src={`${imagesPath}/whiteG.svg`} />*/}
                          {/*  </Button>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                  )
                }
            </div>
          )
        }
        </I18n>
      </NoSSR>
    );
  }
}

ResetPassword = reduxForm({
  form: 'resetPassword',
  validate: resetValidation,
})(ResetPassword);

ResetPassword.defaultProps = {
  error: '',
};

const mapStateToProps = ({
  form, uiInteractions, user, landing,
}) => {
  const { resetPassword } = form;
  const formData = { ...resetPassword };
  const { loading } = uiInteractions;
  return {
    formData,
    loading,
    landing,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleLoading: bindActionCreators(actionCreators.toggleLoading, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
