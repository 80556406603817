// @flow
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

// other
import { pixel } from 'CONFIG';
// components
import { AnimatedBottomToTop, AnimatedSmooth } from 'COMPONENTS/Animated';

// styles
import './hero.scss';
import Button from 'COMPONENTS/Button';
import Line from 'COMPONENTS/Line';
import {Link} from "ROOT/routes";

const { trackers, registerKeys } = pixel;

type Props = {
  description: string,
  buttonText: string,
  baseUrl: string,
  imageName: string,
  title: string,
  imageGraphic: string,
  mobileImage: string,
  buttonLink: string,
  isButtonTarget: boolean,
  isVisible: boolean,
  className?: string,
  style?: Object,
}

const Hero = ({
  title, description, buttonText, isButtonTarget, buttonLink, imageName, imageGraphic, className, style, baseUrl, isVisible, toggleModal, mobileImage, ...rest
}: Props) => (
  <>
    <div className="mobile-hero" style={{ ...style, backgroundImage: `url("${baseUrl}/${mobileImage}")` }} />

    <section className={`hero ${className}`} style={{ ...style, backgroundImage: `url("${baseUrl}/${imageName}")` }} {...rest}>
      {/*<div className="background">*/}
      {/*  <div className="content_container">*/}
      {/*    <AnimatedBottomToTop pose={isVisible ? 'visible' : 'hidden'} className="hero-content-description">*/}
      {/*      <h2>{title}</h2>*/}
      {/*      <p>*/}
      {/*        { ReactHtmlParser(description) }*/}
      {/*      </p>*/}
      {/*      <Line isWhite />*/}
      {/*      <a*/}
      {/*        style={{ display: 'inline-block' }}*/}
      {/*        href=""*/}
      {/*        onClick={(e) => {*/}
      {/*          trackers.registerCustom(registerKeys.HERO);*/}
      {/*        }*/}
      {/*      }*/}
      {/*      >*/}
      {/*        <Button isPrimary>*/}
      {/*          <Link to="Register">*/}
      {/*          {buttonText}*/}
      {/*          </Link>*/}
      {/*        </Button>*/}
      {/*      </a>*/}
      {/*    </AnimatedBottomToTop>*/}
      {/*    {*/}
      {/*    imageGraphic && (*/}
      {/*      <AnimatedSmooth pose={isVisible ? 'visible' : 'hidden'} className="hero-content-graph">*/}
      {/*        <img src={`${baseUrl}/${imageGraphic}`} alt={imageGraphic} />*/}
      {/*      </AnimatedSmooth>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  </div>*/}
      {/*</div>*/}
    </section>
    </>
);

Hero.defaultProps = {
  className: '',
  style: {},
};

export default Hero;
