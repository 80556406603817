const landingData = {
  unishopLogoName: 'unishop_logo.svg',
  unishopDarkLogoName: 'unishop_logo_black.svg',
  header: {
    buttonsTextObj: {
      signIn: 'Sign In',
      register: 'Register',
    },
  },
  heroSection: {
    title: 'Opening an online store has never been easier',
    text: 'Create a modern and beautiful online store today. Start selling online! Quickly, easily, for free.',
    buttonText: 'Start Now',
    imageName: 'hero.png',
  },
  doItYourselfSection: {
    title: 'Do it yourself, no coding necessary',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia',
    featuresArr: [
      {
        text: 'Customize Your Store',
        imgSrc: 'customize_store_icon.svg',
      },
      {
        text: 'Manage Your Products',
        imgSrc: 'manage_products_icon.svg',
      },
      {
        text: 'Share Your Content',
        imgSrc: 'share_content_icon.svg',
      },
      {
        text: 'Sell Globally',
        imgSrc: 'sell_globally_icon.svg',
      },
    ],
  },
  card: {
    backgroundImgName: 'socials_dude_image.svg',
    title: 'Social Commerce',
    text: 'Set up an Unishop Facebook Shop to connect with billions of Facebook users from across the globe. Your Facebook Shop is simple to set up and maintain and enables you to add your online store to your Facebook page in minutes.',
    buttonText: 'Start Now',
  },
  applicationSection: {
    title: 'Use a free app with all the features for shop',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia',
    slidesArr: [
      {
        title: 'Manage orders on the go',
        text: 'Update the status or fulfill an order on the go, with just a few taps.',
      },
      {
        title: 'Add new products',
        text: 'Snap a quick photo of your product and upload it in a matter of seconds.',
      },
      {
        title: 'Share your products',
        text: 'Easily share your products on Facebook and Instagram — so you can promote your business at any time.',
      },
      {
        title: 'Modify inventory',
        text: 'Update product names, prices and descriptions so your customers are always shopping your latest products.',
      },
      {
        title: 'Get notified',
        text: 'Receive a push notification any time an order is placed or a payment has been accepted.',
      },
      {
        title: 'Sell on the go',
        text: 'Accept payments directly from your Ecwid App (coming soon for Android).',
      },
    ],
  },
  checkoutSection: {
    title: 'All types of payments',
    text: 'Set up an Unishop Facebook Shop to connect with billions of Facebook users from across the globe. Your Facebook Shop is simple to set up and maintain and enables you to add your online store to your Facebook page in minutes.',
    buttonText: 'Start Now',
  },
  featuresSection: {
    title: 'Features',
    text: 'Begin your success story!',
    featuresArr: [
      {
        imageName: '20minutes_icon_96x96.svg',
        text: 'Create online stores in just 20 minutes',
      }, {
        imageName: 'twenyfour_seven_icon_96x96.svg',
        text: 'Sell automatically, 24/7',
      }, {
        imageName: 'card_icon_96x96.svg',
        text: 'Accept online payments',
      }, {
        imageName: 'delivery_box_icon_96x96.svg',
        text: 'Customizable delivery options',
      }, {
        imageName: 'stats_icon_96x96.svg',
        text: 'Powerful marketing and SEO features',
      }, {
        imageName: 'lock_icon_96x96.svg',
        text: 'Convenient product catalog',
      }, {
        imageName: 'mobile_icon_96x96.svg',
        text: 'Works great on mobile devices',
      }, {
        imageName: 'shoppingcart_icon_96x96.svg',
        text: 'Convenient and powerful shopping cart',
      }, {
        imageName: 'language_icon_96x96.svg',
        text: 'Multiple language support',
      }, {
        imageName: 'customize_icon_96x96.svg',
        text: 'Do it yourself, no coding necessary',
      },
    ],
  },
  trailSection: {
    textObj: {
      partOne: 'Start your 14-day',
      partTwo: 'free trail',
      partThree: 'today!',
    },
    buttonText: 'Start Trail',
  },
  footer: {
    copyrightText: '© Copyright 2007-2018 UniPAY. All rights reserved.',
    languageText: 'English',
    languageIconName: 'language_18x18.svg',
    logosNamesArr: ['footer_unipay_logo.svg', 'footer_visa_logo.svg', 'footer_mastercard_logo.svg', 'footer_amex_logo.svg', 'footer_ssl_logo.svg', 'footer_thawte_logo.svg', 'footer_sitelock_logo.svg'],
    navigationItemsArr: [
      {
        column: '1',
        navItems: ['About Us', 'Media Kit', 'Press Releases', 'In The News', 'Career'],
      }, {
        column: '0',
        navItems: ['Agency Partner Program', 'Industry Partner Program', 'Ambassador Program'],
      },
    ],
  },
};

export default landingData;
