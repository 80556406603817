import HttpService from './HttpService';

class LandingService extends HttpService {
  static getHomepageData() {
    return this.get('home');
  }

  static getFooterData() {
    return this.get('footer');
  }

  // getDefaultPageData :: string => promise
  static getDefaultPageData(requestUrl) {
    return this.get(`page/${requestUrl}`);
  }

  static getContactLayoutData() {
    return this.get('contact');
  }

  // sendEmail :: obj => promise
  static sendEmail(data) {
    return this.post('contact/send', data);
  }

  static getCookiesNotification() {
    return this.get('cookie');
  }
}

export default LandingService;
