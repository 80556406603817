// @flow
import React, { Component } from 'react';

// other
import env from 'CONFIG/env';

// styles
import './notification.scss';

const { imagesPath } = env;

type Props = {
  children: React.Node,
  onClose?: Function,
  type: 'success' | 'error',
  id: string,
  show: boolean,
}

const close = (id, onClose) => {
  document.getElementById(id).classList.add('hide');
  document.getElementById(id).classList.remove('show');
  if (typeof onClose === 'function') {
    onClose();
  }
};

const Notification = ({
  children, onClose, type, show, id,
}: Props) => (
  <div
    className={`
    top-notification
    ${type}
    ${show ? 'show' : 'hide'}
    `}
    id={id}
  >
    <p>{children}</p>
    <img src={`${imagesPath}/add_24x24_icon_white.svg`} alt="close" onClick={() => close(id, onClose)} />
  </div>
);

Notification.defaultProps = {
  onClose: '',
};

export default Notification;
