import Regexp from './Regexp';
import translations from './translations';

const regexp = new Regexp();
const { isWhitespace } = regexp;

const validate = (values) => {
  const errors = {};
  errors.passwordRequired = ' ';
  errors.passwordMinLength = translations.minLength;
  errors.passwordMaxLength = ' ';
  errors.passwordOnlyLatin = translations.onlyLatin;
  errors.passwordNoWhiteSpace = ' ';
  errors.passwordUppercase = translations.uppercase;
  errors.passwordNumber = translations.number;
  // errors.passwordSymbol = translations.symbol;

  if (!values.password) {
    errors.password = ' ';
  } else {
    errors.passwordRequiredIsDone = true;
    // min length
    if (values.password.length < 8) {
      errors.password = ' ';
    } else {
      errors.passwordMinLengthIsDone = true;
    }
    // max length
    if (values.password.length > 16) {
      errors.password = ' ';
    } else {
      errors.passwordMaxLengthIsDone = true;
    }
    // white space
    if (isWhitespace(values.password)) {
      errors.password = ' ';
    } else {
      errors.passwordNoWhiteSpaceDone = true;
    }
    // only latin
    if (/[^A-Za-z0-9._%!@#$^&*(),?":{}|<>]/.test(values.password)) {
      errors.password = ' ';
    } else {
      errors.passwordOnlyLatinDone = true;
    }
    // uppercase
    if (!/[A-Z]/.test(values.password)) {
      errors.password = ' ';
    } else {
      errors.passwordUppercaseDone = true;
    }
    // number
    if (!/[0-9]/.test(values.password)) {
      errors.password = ' ';
    } else {
      errors.numberDone = true;
    }
    // symbol
    // if (!/[._%!@#$^&*(),?":{}|<>]/.test(values.password)) {
    //   errors.password = ' ';
    // } else {
    //   errors.passwordSymbolDone = true;
    // }
  }

  if (!values.email) {
    errors.email = ' ';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = ' ';
  } else if (values.email.split('@')[0].length > 64) {
    errors.email = ' ';
  } else if (values.email.split('@')[1].length > 250) {
    errors.email = ' ';
  }

  return errors;
};

export default validate;
