// @flow
import React from 'react';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// styles
import './burgerNav.scss';

// other
import env from 'CONFIG/env';

const { imagesPath } = env;

type Props = {
  children: React.Node,
  isBurgerNavOpen: boolean,
  toggleBurgerNav: Function,
  className?: string,
  isFullWidth?: boolean,
}

const BurgerNav = ({
  className, children, isBurgerNavOpen, toggleBurgerNav, isFullWidth, ...rest
}: Props) => {
  document.addEventListener('click', (e) => {
    const clickedObjClassList = e.target.classList;
    if (!clickedObjClassList.contains('burger-nav') && isBurgerNavOpen && !clickedObjClassList.contains('burger-icon')) {
      toggleBurgerNav(false);
    }
  });

  return (
    <div className={`burger-nav ${isBurgerNavOpen ? 'open' : ''} ${isFullWidth ? 'full-width' : ''} ${className}`} {...rest}>
      <img
        onClick={() => toggleBurgerNav(true)}
        className="close-icon"
        src={`${imagesPath}/close_24x24_icon_green.svg`}
      />
      { children }
    </div>
  );
};

BurgerNav.defaultProps = {
  className: '',
  isFullWidth: false,
};

const mapStateToProps = ({ uiInteractions }) => ({
  isBurgerNavOpen: uiInteractions.isBurgerNavOpen,
});

const mapDispatchToProps = dispatch => ({
  toggleBurgerNav: bindActionCreators(actionCreators.toggleBurgerNav, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BurgerNav);
