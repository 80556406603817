import { LandingService } from 'SERVICES';
import env from 'CONFIG/env';

const { userLanguage } = env;

export default (setCookieData) => {
  LandingService.getCookiesNotification().then(({ data }) => {
    setCookieData(data.data.cookie[userLanguage].extras);
  }).catch((error) => {
    console.log(error);
  });
};
