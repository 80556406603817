// @flow
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Animated} from 'react-animated-css';
import ReactHtmlParser from 'react-html-parser';

// components
import SectionTitleWithText from 'COMPONENTS/SectionTitleWithText';
import {pixel} from 'CONFIG';
import Slide from './Slide';

// other
import switchImage from './switchImage';

// style
import './applicationSection.scss';

const {trackers, leadKeys} = pixel;

class ApplicationSection extends PureComponent {
    static async getInitialProps({
                                     className, id, style, title, text, slidesArr, baseUrl, downloadFromIcons, isVisible,
                                 }) {
        return {
            className,
            id,
            style,
            title,
            text,
            slidesArr,
            baseUrl,
            downloadFromIcons,
            isVisible,
        };
    }

    static propTypes = {
        title: PropTypes.string,
        isVisible: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]),
        text: PropTypes.string,
        slidesArr: PropTypes.array,
        downloadFromIcons: PropTypes.array,
        baseUrl: PropTypes.string,
        className: PropTypes.string,
        id: PropTypes.string,
        style: PropTypes.object,
    };

    constructor() {

        super();
        this.intervalId = false;
        this.currentSlide = 0;


    }

    componentDidMount() {
        this.initSlider();
    }

    initSlider = () => {
        if (window.location.pathname !== '/register' && window.location.pathname !== '/signin' && window.location.pathname !== '/reset') {
            this.intervalId = async () => {
                await switchImage(`${this.props.baseUrl}/${this.props.slidesArr[this.currentSlide].extras.image}`, 'application-section-slider');
                this.currentSlide = ((this.currentSlide + 1) % this.props.slidesArr.length);
            }
        }
    };

    stopSlider = () => {
        if (window.location.pathname !== '/register' && window.location.pathname !== '/signin' && window.location.pathname !== '/reset') {
            clearInterval(this.intervalId);
        }

    };

    render() {
        const {
            title,
            text,
            slidesArr,
            downloadFromIcons,
            baseUrl,
            className,
            id,
            style,
            isVisible,
        } = this.props;

        return (
            <section className={`use_free_app_section ${className}`} id={id} style={style}>
                {/*<Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={isVisible}>*/}
                {/*    <SectionTitleWithText>*/}
                {/*        <h2>{title}</h2>*/}
                {/*        <p>*/}
                {/*            {ReactHtmlParser(text)}*/}
                {/*        </p>*/}
                {/*    </SectionTitleWithText>*/}
                {/*</Animated>*/}
                {/*<div className="slider">*/}
                {/*    <div className="text">*/}
                {/*        <div className="slides-items">*/}
                {/*            {*/}
                {/*                slidesArr.map((item, index) => (*/}
                {/*                    <Slide*/}
                {/*                        isVisible={isVisible}*/}
                {/*                        id={item.extras.title.replace(/ /g, '')}*/}
                {/*                        key={index + item.extras.title}*/}
                {/*                        onMouseLeave={this.initSlider}*/}
                {/*                        onMouseOver={*/}
                {/*                            () => {*/}
                {/*                                this.stopSlider();*/}
                {/*                                switchImage(`${baseUrl}/${item.extras.image}`, 'application-section-slider');*/}
                {/*                            }}*/}
                {/*                    >*/}
                {/*                        <h6>{item.extras.title}</h6>*/}
                {/*                        <p>{item.extras.description}</p>*/}
                {/*                    </Slide>*/}
                {/*                ))*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*        <div className="slide-switchers">*/}
                {/*            {*/}
                {/*                slidesArr.map(item => (*/}
                {/*                    <a*/}
                {/*                        className="switcher"*/}
                {/*                        key={item.extras.title.replace(/ /g, '')}*/}
                {/*                        href={`#${item.extras.title.replace(/ /g, '')}`}*/}
                {/*                    />*/}
                {/*                ))*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*        <Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={isVisible}*/}
                {/*                  className="images">*/}
                {/*            {*/}
                {/*                downloadFromIcons.map((item, index) => (*/}
                {/*                    <a*/}
                {/*                        className="download-icons"*/}
                {/*                        key={item.extras.image + index}*/}
                {/*                        onClick={() => {*/}
                {/*                            trackers.leadCustom(leadKeys.APP, {*/}
                {/*                                os: item.extras.link.includes('itunes') ? 'ios' : 'android',*/}
                {/*                                source: 'home'*/}
                {/*                            });*/}
                {/*                            window.open(item.extras.link);*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        <img src={`${baseUrl}/${item.extras.image}`}/>*/}
                {/*                    </a>*/}
                {/*                ))*/}
                {/*            }*/}
                {/*        </Animated>*/}
                {/*    </div>*/}
                {/*    <div className="main_image">*/}
                {/*        <img src={`${baseUrl}/${slidesArr[0].extras.image}`} id="application-section-slider"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </section>
        );
    }
}

ApplicationSection.defaultProps = {
    className: '',
    id: '',
    style: {},
    title: '',
    text: '',
    slidesArr: [],
    downloadFromIcons: [],
    baseUrl: '',
    isVisible: false,
};

export default ApplicationSection;
