const validate = (values) => {
  const errors = {};
  if (!values.coupon) {
    errors.coupon = ' ';
  }

  return errors;
};

export default validate;
