import posed from 'react-pose';

import transition from './transition';

export default posed.div({
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition,
  },
});
