// @flow
import ReactPixel from 'react-facebook-pixel';

const keys = {
  register: {
    HEADER: 'REGISTER_HEADER',
    HERO: 'REGISTER_HERO',
    SOC_MEDIA: 'REGISTER_SOC_MEDIA',
    TRAIL: 'REGISTER_TRAIL',
  },
  lead: {
    APP: 'LEAD_APP',
    UNIPAY_CHECKOUT: 'LEAD_UNIPAY_CHECKOUT',
    SUBSCRIBE: 'LEAD_SUBSCRIBE',
    GE_DOMAINS: 'LEAD_GE_DOMAINS',
    SELL_ON_FB: 'LEAD_SELL_ON_FB',
  },
};

type RegisterTypes = $Keys<typeof keys.register>;
type LeadTypes = $Keys<typeof keys.lead>;

const trackers = {
  registerCustom: (key: RegisterTypes, data = {}) => {
    ReactPixel.trackCustom(key, { ...data });
  },
  leadCustom: (key: LeadTypes, data = {}) => {
    ReactPixel.trackCustom(key, { ...data });
  },
  completeRegister: (data = {}) => {
    ReactPixel.track('CompleteRegistration', { ...data });
  },
  subscribe: (data = {}) => {
    ReactPixel.track('Subscribe', { ...data });
  },
};

export default {
  registerKeys: keys.register,
  leadKeys: keys.lead,
  trackers,
};
