// @flow
import React from 'react';


type Props = {
  col: Array
}

export default ({ col }: Props) => (
  <div className="nav_col">
    {
      col.map((item, index) => (
        <a
          href={item.link}
          key={index + item.name}
          target={item.type === 'external_link' ? '_blank' : '_self'}
        >
          {item.name}
        </a>
      ))
    }
  </div>
);
