// @flow
import React from 'react';

// other
import env from 'CONFIG/env';

// styles
import './passwordTooltip.scss';

type Props = {
  formData: Object,
  t: Function,
};

const { imagesPath } = env;

export default ({ formData, t }: Props) => {
  let errors = {};
  if (Object.keys(formData).length > 0) {
    errors = formData.syncErrors;
  } else {
    return null;
  }

  const IsRequired = () => (
    <div className={`suggestion ${errors.passwordRequiredIsDone ? 'green-note' : 'gray-txt'}`}>
      <img src={`${imagesPath}/${errors.passwordRequiredIsDone ? 'check_icon' : 'error_icon_grey_02'}.svg`} />
      <p>{t(`back.${errors.passwordRequired}`)}</p>
    </div>
  );
  const MinLength = () => (
    <div className={`suggestion ${errors.passwordMinLengthIsDone ? 'green-note' : 'gray-txt'}`}>
      <img src={`${imagesPath}/${errors.passwordMinLengthIsDone ? 'check_icon' : 'error_icon_grey_02'}.svg`} />
      <p>{t(`back.${errors.passwordMinLength}`)}</p>
    </div>
  );
  const MaxLength = () => (
    <div className={`suggestion ${errors.passwordMaxLengthIsDone ? 'green-note' : 'gray-txt'}`}>
      <img src={`${imagesPath}/${errors.passwordMaxLengthIsDone ? 'check_icon' : 'error_icon_grey_02'}.svg`} />
      <p>{t(`back.${errors.passwordMaxLength}`)}</p>
    </div>
  );
  const OnlyLatin = () => (
    <div className={`suggestion ${errors.passwordOnlyLatinDone ? 'green-note' : 'gray-txt'}`}>
      <img src={`${imagesPath}/${errors.passwordOnlyLatinDone ? 'check_icon' : 'error_icon_grey_02'}.svg`} />
      <p>{t(`back.${errors.passwordOnlyLatin}`)}</p>
    </div>
  );
  const WhiteSpace = () => (
    <div className={`suggestion ${errors.passwordNoWhiteSpaceDone ? 'green-note' : 'gray-txt'}`}>
      <img src={`${imagesPath}/${errors.passwordNoWhiteSpaceDone ? 'check_icon' : 'error_icon_grey_02'}.svg`} />
      <p>{t(`back.${errors.passwordNoWhiteSpace}`)}</p>
    </div>
  );
  const Uppercase = () => (
    <div className={`suggestion ${errors.passwordUppercaseDone ? 'green-note' : 'gray-txt'}`}>
      <img src={`${imagesPath}/${errors.passwordUppercaseDone ? 'check_icon' : 'error_icon_grey_02'}.svg`} />
      <p>{t(`back.${errors.passwordUppercase}`)}</p>
    </div>
  );
  const Number = () => (
    <div className={`suggestion ${errors.numberDone ? 'green-note' : 'gray-txt'}`}>
      <img src={`${imagesPath}/${errors.numberDone ? 'check_icon' : 'error_icon_grey_02'}.svg`} />
      <p>{t(`back.${errors.passwordNumber}`)}</p>
    </div>
  );
  // const Symbol = () => (
  //   <div className={`suggestion ${errors.passwordSymbolDone ? 'green-note' : 'gray-txt'}`}>
  //     <img src={`${imagesPath}/${errors.passwordSymbolDone ? 'check_icon' : 'error_icon_grey_02'}.svg`} />
  //     <p>{t(`back.${errors.passwordSymbol}`)}</p>
  //   </div>
  // );


  return (
    <div className="tooltip-container" style={{top: '40px', left: '10px'}}>
      <strong>{t('back.auth_register_password_must_be')}</strong>
      {/*<IsRequired />*/}
      <MinLength />
      {/*<MaxLength />*/}
      <OnlyLatin />
      {/*<WhiteSpace />*/}
      <Uppercase />
      <Number />
      {/*<Symbol />*/}
    </div>
  );
};
