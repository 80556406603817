import { AppService } from 'SERVICES';

export default (isData, setData) => {
  if (!isData) {
    AppService.getManageShop().then(({ data }) => {
      const { store } = data.data;
      let storeStatus = 'trail';
      if (!store.trial && store.status) {
        storeStatus = 'paid';
      } else if (!store.trial && !store.status) {
        storeStatus = 'expired';
      }
      setData({
        manageStoreData: {
          ...data.data,
          storeStatus,
        },
      });
    }).catch((error) => {
      console.log(error);
    });
  }
};
