export { default as rUpgradeCheckout } from './upgradeCheckout';
export { default as rUpgradePrices } from './upgradePrices';
export { default as rOrders } from './orders';
export { default as rInvoicePdf } from './invoicePdf';
export { default as rNotificationSeen } from './notificationSeen';
export { default as rDefaultLayout } from './defaultLayout';
export { default as rGetManageStore } from './getManageStore';
export { default as rSellOnFb } from './sellOnFb';
export { default as rCheckoutCard } from './checkoutCard';
export { default as rDownloadAppCard } from './downloadAppCard';
export { default as rhelpSection } from './helpSection';
export { default as rDomainsInfo } from './domainsInfo';
export { default as rCheckCoupon } from './checkCoupon';
