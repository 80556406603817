import { checkAndSetUserLanguage } from 'FUNCTIONS';

export default {
  imagesPath: '/static/img',
  // env -->
  // PROD:
  apiUrl: 'https://api.unishop.ge/api/',
  baseUrl: 'https://api.unishop.ge',
  //googleReCaptchaKey: '6LclLO8UAAAAAKHf2KhOjpb-nAoinN71l7CGhugj',
  googleReCaptchaKey: '6LcjR3wUAAAAAKO4zeV-UxvzJdaeULE06QMNcY_V',
  pixelId: '537205023459859',
  // DEV:
  // apiUrl: 'http://unishop.unipay.com/api/',
  // baseUrl: 'http://unishop.unipay.com',
  // googleReCaptchaKey: '6Ldlt34UAAAAADjGUQLlnF5fUrPstfHWirCONvOJ',
  // pixelId: '',
  // <-- env
  userLanguage: checkAndSetUserLanguage() || 'ka',
  startAnimationTime: 1500,
  passwordMaxLength: '16',
  emailMaxLength: '320',
  brandNameMaxLength: '64',
  phoneNumberMaxLength: '10',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  EXPIRES_TOKEN_IN: 'EXPIRES_TOKEN_IN',
  LOGIN_TIME: 'LOGIN_TIME',
  REFRESH_TOKEN_MUST_SEND_TIME: 'REFRESH_TOKEN_MUST_SEND_TIME',
  NOTIFY_USER_ABOUT_SESSION_END_TIME: 'NOTIFY_USER_ABOUT_SESSION_END_TIME',
  AUTO_LOGOUT_TIME: 'AUTO_LOGOUT_TIME',
  USER_LAST_ACTIVITY: 'USER_LAST_ACTIVITY',
};
