// @flow
import actionTypes from './actionTypes';

// toggleLoading :: bool => func => obj
const toggleLoading = isLoading => dispatch => dispatch({ type: actionTypes.LOADING, isLoading });

// toggleLoading :: str, bool => func => obj
const toggleCustomLoader = ({ id, isLoading }) => dispatch => dispatch({ type: actionTypes.TOGGLE_CUSTOM_LOADER, payload: { id, isLoading } });

const toggleContentLoading = isLoading => dispatch => dispatch({ type: actionTypes.CONTENT_LOADING, isLoading });

const callSuccess = () => dispatch => dispatch({ type: actionTypes.SUCCESS });

// toggleLoading :: string => func => obj
const callErrorMessage = errorMessage => (dispatch) => {
  dispatch({ type: actionTypes.ERROR, errorMessage });
};

// toggleModal :: (string, bool) => func => obj
const toggleModal = ({ modalId, isOpen }) => (dispatch) => {
  dispatch({
    type: actionTypes.TOGGLE_MODAL,
    payload: {
      modalId,
      isOpen,
    },
  });
};

// getDashboardData :: (obj) => func => obj
const getDashboardData = data => (dispatch) => { dispatch({ type: actionTypes.GET_DASHBOARD_DATA, data }); };

// getDashboardLayoutData :: (obj) => func => obj
const getDashboardLayoutData = data => (dispatch) => { dispatch({ type: actionTypes.GET_DASHBOARD_LAYOUT_DATA, data }); };

// toggleDrowdown :: (string, bool) => func => obj
const toggleDrowdown = ({ id, isOpen }) => (dispatch) => { dispatch({ type: actionTypes.TOGGLE_DROPDOWN, payload: { id, isOpen } }); };

// toggleDrowdown :: (bool) => func => obj
const toggleNotificationSeen = isSeen => (dispatch) => { dispatch({ type: actionTypes.TOGGLE_NOTIFICATION_SEEN, isSeen }); };

// getDashboardPrices :: (obj) => func => obj
const getUpgradePrices = data => (dispatch) => { dispatch({ type: actionTypes.GET_UPGRADE_PRICES, data }); };

// getOrders :: (arr, num, bool, bool) => func => obj
const getOrders = ({
  orders, pagesQuantity, isMoreThenOnePage, isData,
}) => (dispatch) => {
  dispatch({
    type: actionTypes.GET_ORDERS,
    payload: {
      orders, pagesQuantity, isMoreThenOnePage, isData,
    },
  });
};

type customMessageProps = {
  id: string,
  messageType: 'error' | 'success' | 'warning',
  isMessage: boolean,
  message: string,
}
const callCustomMessage = ({
  id, messageType, isMessage, message,
}: customMessageProps) => (dispatch) => {
  dispatch({
    type: actionTypes.CALL_CUSTOM_MESSAGE,
    payload: {
      id, messageType, isMessage, message,
    },
  });
};

// clearMessage :: string => func => obj
const clearMessage = id => (dispatch) => { dispatch({ type: actionTypes.CLEAR_MESSAGE, id }); };

const toggleSidebar = () => (dispatch) => { dispatch({ type: actionTypes.TOGGLE_SIDEBAR }); };

const toggleBurgerNav = isOpen => (dispatch) => { dispatch({ type: actionTypes.TOGGLE_BURGER, isOpen }); };

// setHomepageData :: obj => func => obj
const setHomepageData = data => (dispatch) => { dispatch({ type: actionTypes.SET_HOMEPAGE_DATA, data }); };

// setLandingFooterData :: obj => func => obj
const setLandingFooterData = data => (dispatch) => { dispatch({ type: actionTypes.SET_LANDING_FOOTER_DATA, data }); };

// setLandingHeaderData :: obj => func => obj
const setLandingHeaderData = data => (dispatch) => { dispatch({ type: actionTypes.SET_LANDING_HEADER_DATA, data }); };

// setDefaultLayoutData :: obj => func => obj
const setDefaultLayoutData = data => (dispatch) => { dispatch({ type: actionTypes.SET_DEFAULT_LAYOUT_DATA, data }); };

// setContactPageData :: obj => func => obj
const setContactPageData = data => (dispatch) => { dispatch({ type: actionTypes.SET_CONTACT_PAGE_DATA, data }); };

// setSubscribers :: string, subscriber => func => obj
const setSubscribers = ({ id, subscriber }) => (dispatch) => { dispatch({ type: actionTypes.SET_SUBSCRIBERS, payload: { id, subscriber } }); };

// setRecaptcha :: string => func => obj
const setRecaptcha = response => (dispatch) => { dispatch({ type: actionTypes.SET_RECAPTCHA, response }); };

const removeRecaptcha = () => (dispatch) => { dispatch({ type: actionTypes.REMOVE_RECAPTCHA }); };

// setCookieData :: obj => func => obj
const setCookieData = data => (dispatch) => { dispatch({ type: actionTypes.SET_COOKIE_DATA, data }); };

// setCookieData :: string, bool => func => obj
const toggleEditForm = ({ formId, isEditable }) => (dispatch) => { dispatch({ type: actionTypes.TOGGLE_EDIT_FORM, payload: { formId, isEditable } }); };

// setCookieData :: string, string => func => obj
const switchPage = ({ page, activePageId }) => (dispatch) => { dispatch({ type: actionTypes.SWITCH_PAGE, payload: { page, activePageId } }); };

export default {
  toggleLoading,
  callSuccess,
  callErrorMessage,
  toggleModal,
  getDashboardData,
  toggleContentLoading,
  getDashboardLayoutData,
  toggleDrowdown,
  callCustomMessage,
  toggleNotificationSeen,
  getUpgradePrices,
  getOrders,
  clearMessage,
  toggleSidebar,
  toggleBurgerNav,
  setHomepageData,
  setLandingFooterData,
  setDefaultLayoutData,
  setContactPageData,
  setSubscribers,
  setRecaptcha,
  removeRecaptcha,
  setLandingHeaderData,
  setCookieData,
  toggleEditForm,
  switchPage,
  toggleCustomLoader,
};
