// @flow
import React from 'react';

// components
import { Animated } from 'react-animated-css';

type Props = {
  children: React.Node,
  isVisible: boolean | number,
};

const Feature = ({ children, isVisible }: Props) => (
  <Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={isVisible} className="feature">
    {children}
  </Animated>
);

export default Feature;
