// @flow
import React from 'react';

// components
import { LoadableComponent } from 'HOCS';
import { AnimatedBottomToTop } from 'COMPONENTS/Animated';
import SectionTitleWithText from 'COMPONENTS/SectionTitleWithText';
import Feature from './Feature';

// styles
import './doYourselfSection.scss';

type Props = {
  title: string,
  text: string,
  featuresArr: Array,
  baseUrl: string,
  isVisible: boolean,
  className?: string,
  id?: string,
  style?: Object,
}

const DoYourselfSection = ({
  title, text, featuresArr, baseUrl, className, id, style, isVisible,
}: Props) => (
  <section className={`doYourselfSection container-on-responsive ${className}`} id={id} style={style}>
    {/*<AnimatedBottomToTop pose={isVisible ? 'visible' : 'hidden'}>*/}
    {/*  <SectionTitleWithText>*/}
    {/*    <h2>{title}</h2>*/}
    {/*    <span dangerouslySetInnerHTML={{ __html: text }} />*/}
    {/*  </SectionTitleWithText>*/}
    {/*  <div className="features">*/}
    {/*    {*/}
    {/*      featuresArr.map((item, index) => (*/}
    {/*        <Feature key={index + item.extras.title}>*/}
    {/*          <img src={`${baseUrl}/${item.extras.image}`} />*/}
    {/*          <p>{item.extras.title}</p>*/}
    {/*        </Feature>*/}
    {/*      ))*/}
    {/*    }*/}
    {/*  </div>*/}
    {/*</AnimatedBottomToTop>*/}
  </section>
);

DoYourselfSection.defaultProps = {
  className: '',
  id: '',
  style: {},
};

export default LoadableComponent(DoYourselfSection);
