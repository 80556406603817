import { UserService } from 'SERVICES';

export default (toggleModal, toggleLoading, loading) => {
  toggleLoading(true);
  if (!loading) {
    UserService.refreshToken().then(({ data }) => {
      const { token } = data.data;
      UserService.setUser(token);
      toggleLoading(false);
      toggleModal({ modalId: 'session_ends_notification', isOpen: false });
    }).catch((error) => {
      console.log(error);
      toggleLoading(false);
      toggleModal({ modalId: 'session_ends_notification', isOpen: false });
    });
  }
};
