import { AppService, UserService } from 'SERVICES';

export default (isDashboardData, getDashboardData) => {
  if (isDashboardData) {
    UserService.callProtector();
  }

  if (!isDashboardData) {
    AppService.getUser().then(({ data }) => {
      const dataObj = data.data;
      const { store } = dataObj;
      // if (
      //   notifications
      //   && notifications[userLanguage]
      //   && notifications[userLanguage].dashboard
      //   && notifications[userLanguage].dashboard.status
      //   && !notifications[userLanguage].dashboard.seen) {
      //   toggleNotificationSeen({ isSeen: false });
      // } else {
      //   toggleNotificationSeen({ isSeen: true });
      // }

      let storeStatus = 'trail';
      if (!store.trial && store.status) {
        storeStatus = 'paid';
      } else if (!store.trial && !store.status) {
        storeStatus = 'expired';
      }
      getDashboardData({
        ...dataObj,
        storeStatus,
        isData: true,
      });
    }).catch(({ data }) => {
      const { message } = data;
      getDashboardData({
        isData: false,
      });
      UserService.callProtector(message === 'Unauthenticated.');
      console.log(data);
    });
  }
};
