// @flow
import React from 'react';
import { Animated } from 'react-animated-css';

type Props = {
  children: React.Node,
  isVisible: boolean,
};

const Slide = ({ children, isVisible, ...rest }: Props) => (
  <div className="slide" {...rest}>
    {/*<Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={isVisible}>*/}
    {/*  {children}*/}
    {/*</Animated>*/}
  </div>
);

export default Slide;
