import { AppService } from 'SERVICES';

export default (isData, setData) => {
  if (!isData) {
    AppService.getCheckoutCard().then(({ data }) => {
      setData({
        checkoutCard: {
          ...data.data,
        },
      });
    }).catch((error) => {
      console.log(error);
    });
  }
};
