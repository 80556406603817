// @flow
import { UserService } from 'SERVICES';

type Args = {
  pageNumber: number,
  getOrders: Function,
}

export default ({ pageNumber, getOrders }: Args) => {
  const quantity = 8;
  const requestData = {
    quantity,
    from: pageNumber * quantity,
  };

  UserService.getOrders(requestData).then(({ data }) => {
    let pagesQuantity = 0;
    if (data.data.total >= 1 && data.data.total <= quantity) {
      pagesQuantity = 1;
    } else if (data.data.total > 8) {
      pagesQuantity = Math.ceil(data.data.total / quantity);
    }

    getOrders({
      isData: true,
      orders: data.data.orders,
      pagesQuantity,
      isMoreThenOnePage: pagesQuantity > 1,
    });
  }).catch((error) => {
    console.log(error);
  });
};
