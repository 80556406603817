// @flow
import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';

// redux
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// components
import Button from 'COMPONENTS/Button';
import { renderField } from 'COMPONENTS/Form';
import { Link } from 'ROOT/routes';

// styles
import 'bootstrap/scss/bootstrap.scss';
import 'STYLES/main.scss';
import './login.scss';

// other
import { loginValidation } from 'VALIDATION';
import env from 'CONFIG/env';
import { rLogin, rLandingFooter } from 'REQUESTS';

// data

const {
  imagesPath, passwordMaxLength, emailMaxLength, userLanguage,
} = env;

class Login extends Component {
  static async getInitialProps({
    handleSubmit, formData, loading, toggleLoading, error, callCustomMessage, query,
  }) {
    return {
      handleSubmit, formData, loading, toggleLoading, error, callCustomMessage, query,
    };
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    error: PropTypes.string,
    callCustomMessage: PropTypes.func.isRequired,

  };

  render() {
    const {
      handleSubmit, toggleLoading, callCustomMessage, error, loading, formData, query,
    } = this.props;

    return (
      <NoSSR>
        <I18n>
          {
          t => (
            <div className="login-component">
              <div className="CardWhite LoginGrid">
                <div className="LoginImage">
		        <a href="https://unishop.ge">
                    <img src={`${imagesPath}/unishop_logo_black.svg`} className="logo positionAbsolute" />
                  </a>
                  <img src={`${imagesPath}/auth/signIn.svg`} />
                </div>
                <div className="LoginFormSection">
                  <a href="https://unishop.ge">
                    <img src={`${imagesPath}/unishop_logo_black.svg`} className="logo positionAbsolute1" />
                  </a>
                  <form
                      className="form_container"
                      onSubmit={handleSubmit(() => rLogin(formData.values, toggleLoading, callCustomMessage))}
                  >
                    <h4 className="login-page-title">{t('back.auth_login_title')}</h4>
                    <Field
                        name="email"
                        type="text"
                        component={renderField}
                        label="auth_login_email"
                        maxLength={emailMaxLength}
                    />
                    <Field
                        name="password"
                        type="password"
                        identifier="login-password"
                        component={renderField}
                        label="auth_login_password"
                        imagesPath={imagesPath}
                        maxLength={passwordMaxLength}
                    />
                    {
                      error && <p className="main_error_message">{ error }</p>
                    }
                    <p className="forgot_password_section">
                      <Link route="ResetPassword">
                        {t('back.auth_login_forget_password')}
                      </Link>
                    </p>
                    <div className="centered_button">
                      <Button isLoading={loading} className="bgBlueButton" type="submit">{t('back.auth_login_button')}</Button>
                    </div>
                  </form>
                  <p className="register_section">
                    <span>{t('back.do_not_have_account')} </span>
                    <Link className="blueHover" route="Register">
                       {t('back.register')}
                    </Link>
                  </p>
                  {/*<p className="beforeAfterSection">*/}
                  {/*  <span>{t('back.or_connect_using')} </span>*/}
                  {/*</p>*/}
                  {/*<div className="Soc_Section">*/}
                  {/*  <Button className="socButton FbBlue">*/}
                  {/*    <img className="IconSOC F" src={`${imagesPath}/FacebookL.svg`} />*/}
                  {/*    <img className="IconSOC F1" src={`${imagesPath}/whiteF.svg`} />*/}
                  {/*  </Button>*/}
                  {/*  <Button className="socButton GRed">*/}
                  {/*    <img className="IconSOC G" src={`${imagesPath}/google.png`} />*/}
                  {/*    <img className="IconSOC G1" src={`${imagesPath}/whiteG.svg`} />*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          )
        }
        </I18n>
      </NoSSR>
    );
  }
}

Login.defaultProps = {
  error: '',
};

Login = reduxForm({
  form: 'login',
  validate: loginValidation,
})(Login);

const mapStateToProps = ({ form, uiInteractions }) => {
  const loginForm = form.login;
  const formData = { ...loginForm };
  const { loading } = uiInteractions;
  return {
    formData,
    loading,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleLoading: bindActionCreators(actionCreators.toggleLoading, dispatch),
  callCustomMessage: bindActionCreators(actionCreators.callCustomMessage, dispatch),
  removeRecaptcha: bindActionCreators(actionCreators.removeRecaptcha, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(Login);
