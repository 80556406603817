const validate = (values) => {
  const errors = {};

  if (!values.brand_name) {
    errors.brand_name = ' ';
  } else if (!/^[A-Z0-9 _]*[A-Z0-9][A-Z0-9 _]*$/i.test(values.brand_name)) { // only latin, numbers and space
    errors.brand_name = ' ';
  } else if (values.brand_name.length < 2) {
    errors.brand_name = ' ';
  } else if (values.brand_name.length > 64) {
    errors.brand_name = ' ';
  }

  if (values.number && values.number.length < 9) {
    errors.number = ' ';
  } else if (values.number && /[a-zA-z]/i.test(values.number)) {
    errors.number = ' ';
  }

  return errors;
};

export default validate;
