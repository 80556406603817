// @flow
import React from 'react';
import ContentLoader from 'react-content-loader';

import './userMenu.scss';

export default () => (
  <ContentLoader
    height={40}
    width={40}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    className="user_icon_loader"
  >
    <rect x="202.6" y="70" rx="0" ry="0" width="0" height="0" />
    <rect x="NaN" y="NaN" rx="0" ry="0" width="NaN" height="NaN" />
    <circle cx="19.97" cy="19.88" r="17.69" />
  </ContentLoader>
);
