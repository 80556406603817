import { UserService } from 'SERVICES';

export default (toggleLoading) => {
  toggleLoading(true);
  UserService.deleteAccount().then(() => {
    window.location = '/';
  }).catch((error) => {
    console.log(error);
  });
};
