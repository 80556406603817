// @flow
import React from 'react';

// comps
import Spinner from 'COMPONENTS/Spinner';

// other
import env from 'CONFIG/env';

import './button.scss';

const { imagesPath } = env;

type Props = {
  children: React.node,
  className?: string,
  isTransparent?: boolean,
  isPrimary?: boolean,
  isOutlined?: boolean,
  isUppercase?: boolean,
  isDisabled?: boolean,
  type?: string,
  isOnWhiteBackground?: boolean,
  isLoading?: boolean,
  isWhiteBordered?: boolean,
  isDanger?: boolean,
  iconName?: string,
};

const Button = ({
  children, className, isTransparent, isPrimary, isOutlined, isUppercase, isWhiteBordered, isDisabled, type, isOnWhiteBackground, isLoading, isDanger, iconName, ...rest
}: Props) => (
  <button
    {...rest}
    className={`
      main_button regular
      ${isOnWhiteBackground ? 'onWhite' : ''}
      ${isPrimary ? '' : className}
      ${isDisabled ? 'disabled' : ''}
      ${isTransparent ? 'transparent' : ''}
      ${isPrimary ? 'primary' : ''}
      ${isOutlined ? 'outlined' : ''}
      ${isUppercase ? 'uppercase' : ''}
      ${isWhiteBordered ? 'white-bordered' : ''}
      ${isDanger ? 'danger' : ''}
      ${iconName ? 'with-icon' : ''}
      ${isLoading ? 'loadable-btn' : ''}
    `}
    type={isLoading ? '' : type}
  >
    { isLoading && <Spinner className="btn-loader" isWhite={isPrimary} /> }
    {
      isPrimary ? (
        <div className={`white_bg_for_hover ${className} ${isLoading ? 'invisible-txt' : ''}`}>
          {children}
        </div>
      ) : <span className={isLoading ? 'invisible-txt' : ''}>{children}</span>
    }
    {
      iconName && <img src={`${imagesPath}/${iconName}`} alt="Icon" className="button-icon" />
    }
  </button>
);

Button.defaultProps = {
  id: '',
  className: '',
  isTransparent: false,
  isPrimary: false,
  isOutlined: false,
  isUppercase: false,
  isDisabled: false,
  isOnWhiteBackground: false,
  isLoading: false,
  isWhiteBordered: false,
  isDanger: false,
  type: 'button',
  style: {},
  onClick: () => {},
  iconName: '',
};

export default Button;
