// @flow
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

// other
import { pixel } from 'CONFIG';

// style
import './trailSection.scss';
import Button from 'COMPONENTS/Button/Button';
import {Link} from "ROOT/routes";

type Props = {
  buttonText: string,
  toggleModal: Function,
  text: Object,
  className?: string,
  id?: string,
  style?: Object,
}

const { trackers, registerKeys } = pixel;

const TrailSection = ({
  buttonText, text, className, id, style, toggleModal,
}: Props) => (
  <section className={`trailSection ${className}`} id={id} style={style}>
    {/*{ ReactHtmlParser(text) }*/}
    {/*<a*/}
    {/*  href=""*/}
    {/*  onClick={(e) => {*/}

    {/*  }}*/}
    {/*>*/}
    {/*  <Button onClick={() => trackers.registerCustom(registerKeys.TRAIL)} isOutlined className="uppercase_bold_font">*/}
    {/*      <Link to="Register">*/}
    {/*      {buttonText}*/}
    {/*      </Link>*/}
    {/*  </Button>*/}
    {/*</a>*/}
  </section>
);

TrailSection.defaultProps = {
  className: '',
  id: '',
  style: {},
};

export default TrailSection;
