// @flow
import { LandingService } from 'SERVICES';

type Args = {
  setLandingFooterData: Function,
  isData: boolean,
}

export default ({ setLandingFooterData, isData }: Args) => {
  if (isData) {
    return;
  }
  LandingService.getFooterData().then(({ data }) => {
    setLandingFooterData(data.data);
  }).catch((error) => {
    console.log(error);
  });
};
