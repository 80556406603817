import { timer } from 'rxjs';
import { timestamp as rxTimestamp } from 'rxjs/operators';
import jsCookie from 'js-cookie';

const source = timer(0, 10000).pipe(rxTimestamp());

const subscribtionAutoLogout = source.subscribe(({ timestamp }) => {
  const logOutTime = parseInt(jsCookie.get('AUTO_LOGOUT_TIME'), 10);
  if (logOutTime && timestamp >= logOutTime) {
    jsCookie.remove('ACCESS_TOKEN');
    jsCookie.remove('REFRESH_TOKEN');
    jsCookie.remove('EXPIRES_TOKEN_IN');
    jsCookie.remove('LOGIN_TIME');
    jsCookie.remove('REFRESH_TOKEN_MUST_SEND_TIME');
    jsCookie.remove('NOTIFY_USER_ABOUT_SESSION_END_TIME');
    jsCookie.remove('AUTO_LOGOUT_TIME');
    window.location = '/?session_ended=true';
  }
});

export default subscribtionAutoLogout;
