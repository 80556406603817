export default class Regexp {
  constructor() {
    this.oneUppercase = '(?=.*?[A-Z])';
    this.oneLowercase = '(?=.*?[a-z])';
    this.oneDigit = '(?=.*?[0-9])';
    this.oneSpecialChar = '(?=.*?[#?!@$%^&*-])';
    this.minimalLength = '.{8,}';

    this.isStrongPassword = this.isStrongPassword.bind(this);
  }

  // isStrongPassword :: string => bool
  isStrongPassword(password) {
    const strongPassword = new RegExp(`^${this.oneLowercase}${this.oneUppercase}${this.oneSpecialChar}${this.minimalLength}$`);
    return strongPassword.test(password);
  }

  // isWhitespace :: string => bool
  isWhitespace(word) {
    return /\s/.test(word);
  }
}
