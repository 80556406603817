// @flow
import React from 'react';
import { I18n } from 'react-i18next';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// other
import env from 'CONFIG/env';
import { rLogOut } from 'REQUESTS';

// components
import { LoadableComponent } from 'HOCS';
import UserDropdown from './UserDropdown';
import LoadingUserMenu from './LoadingUserMenu';

// styles
import './userMenu.scss';

type Props = {
  userData: Object,
  storeId: string,
  brandName: string,
  toggleDrowdown: Function,
  dropdowns: Object,
  onLanding?: boolean,
  isOnWhiteBackground?: boolean,
}

const { imagesPath } = env;

const UserMenu = ({
  userData, storeId, toggleDrowdown, dropdowns, onLanding, brandName, isOnWhiteBackground, ...rest
}: Props) => {

  document.addEventListener('click', (e) => {
    const clickedObjClassList = e.target.classList;
    if (dropdowns.user_dropdown && dropdowns.user_dropdown.isOpen && !clickedObjClassList.contains('user_initials')) {
      toggleDrowdown({ id: 'user_dropdown', isOpen: false });
    }
  });

  return (
    <I18n>
      {
        t => (
          <div className={`user_menu ${onLanding ? 'user-menu-on-homepage' : ''}`} {...rest}>
            <span className={`brand-name ${onLanding ? 'white-name' : ''} ${isOnWhiteBackground ? 'dark-name' : ''}`}>
              { brandName }
            </span>
            <div
              className="user_initials circle"
              onClick={() => toggleDrowdown({ id: 'user_dropdown', isOpen: dropdowns.user_dropdown ? !dropdowns.user_dropdown.isOpen : true })}
            >
              <img className="user_initials" src={`${imagesPath}/profile_24x24_icon_white.svg`} />
            </div>
            <UserDropdown onLanding={onLanding} email={userData.email} storeId={storeId} logOutRequest={rLogOut} />
          </div>
        )
      }
    </I18n>
  );
};

UserMenu.defaultProps = {
  onLanding: false,
};

const mapStateToProps = ({ uiInteractions }) => {
  const { dropdowns } = uiInteractions;
  return { dropdowns };
};

const mapDispatchToProps = dispatch => ({
  toggleDrowdown: bindActionCreators(actionCreators.toggleDrowdown, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadableComponent(UserMenu, LoadingUserMenu));
