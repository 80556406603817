import { UserService } from 'SERVICES';

export default (toggleLoading, toggleDrowdown) => {
  toggleLoading(true);
  if (toggleDrowdown) {
    toggleDrowdown({
      id: 'user_dropdown',
      isOpen: false,
    });
  }
  UserService.logOut().then(() => {
    UserService.clearUserSession();
    UserService.callProtector(true, false, true);
    toggleLoading(false);
  }).catch((error) => {
    UserService.clearUserSession();
    UserService.callProtector(true, false, true);
    toggleLoading(false);
    console.log(error);
  });
};
