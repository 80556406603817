// @flow
import React from 'react';

import NavCol from './NavCol';

type Props = {
  navigationItemsArr: Array
}

export default ({ navigationItemsArr }: Props) => {
  const cols = [];
  // define empty cols
  navigationItemsArr.map((navItem) => {
    cols[navItem.column] = [];
  });

  // pass data into cols
  navigationItemsArr.map((navItem) => {
    cols[navItem.column].push(navItem);
  });


  return (
    <nav>
      {
        cols.map((col, index) => (
          <NavCol col={col} key={`${index}nav-col`} />
        ))
      }
    </nav>
  );
};
