import { UserService } from 'SERVICES';
import { SubmissionError } from 'redux-form';

import { pixel } from 'CONFIG';

const { trackers } = pixel;

// func :: obj, func, func, string, func => bool || promise
export default (formData, toggleLoading, callSuccess, recaptchaResponse, removeRecaptcha) => {
  toggleLoading(true);
  const dataForSubmit = {
    ...formData.values,
    recaptcha_response: recaptchaResponse,
  };
  return UserService.register(dataForSubmit).then(() => {
    toggleLoading(false);
    callSuccess();
    removeRecaptcha();
    trackers.completeRegister({
      category: formData.values.categoryKey,
    });
    window.grecaptcha.reset();
  }).catch(({ data }) => {
    let message = null;
    if (data && data.message) {
      message = data.message;
    }
    const errors = {};
    let _error = '';

    toggleLoading(false);
    removeRecaptcha();
    window.grecaptcha.reset();

    if (typeof message === 'object' && message !== null) {
      const keys = Object.keys(message);
      keys.map((key) => {
        errors[key] = message[key][0];
      });
    } else {
      _error = message || '';
    }

    throw new SubmissionError({
      ...errors,
      _error,
    });
  });
};
