import HttpService from './HttpService';
import UserService from './UserService';

class DashboardService extends HttpService {
  static getDashboardData() {
    return this.get('auth/dashboard', UserService.generateBearer());
  }

  static getDashboardLayoutData() {
    return this.get('auth/layouts', UserService.generateBearer());
  }

  // upgradePrices :: () => promise
  static upgradePrices() {
    return this.get('/auth/prices', UserService.generateBearer());
  }

  static getUser() {
    return this.get('auth/get-user', UserService.generateBearer());
  }

  static getManageShop() {
    return this.get('auth/dashboard/manageShop', UserService.generateBearer());
  }

  static getSellOnFb() {
    return this.get('auth/dashboard/facebook', UserService.generateBearer());
  }

  static getCheckoutCard() {
    return this.get('auth/dashboard/unipayCheckout', UserService.generateBearer());
  }

  static getAppDownloadCard() {
    return this.get('auth/dashboard/app', UserService.generateBearer());
  }

  static getHelpSection() {
    return this.get('auth/dashboard/needHelp', UserService.generateBearer());
  }

  static getDomaincInfo() {
    return this.get('auth/dashboard/domains', UserService.generateBearer());
  }

  static checkCoupon({ code }) {
    return this.post('auth/coupon-check', { code }, UserService.generateBearer());
  }
}

export default DashboardService;
