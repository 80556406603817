// (domElement) => true | false
export default (element) => {
  if (window.location.pathname !== '/register' && window.location.pathname !== '/signin' && window.location.pathname !== '/reset') {
        const elementPosition = element.getBoundingClientRect();
        return elementPosition.height - elementPosition.top >= 0
            && elementPosition.left >= 0
            && elementPosition.right <= (window.innerWidth || document.documentElement.clientWidth)
            && elementPosition.height - elementPosition.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      }

};
