import { UserService } from 'SERVICES';

// func :: obj, func, func => bool || promise
export default (token, toggleLoading) => {
  toggleLoading(true);
  return UserService.activateAccount(token).then(() => {
    window.location.assign('/?activate_account=1');
    toggleLoading(false);
  }).catch(({ data }) => {
    const { message } = data;
    window.location.assign('/?activate_account=0');
    // callErrorMessage(message);
    toggleLoading(false);
  });
};
