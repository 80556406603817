// @flow
import React from 'react';

type Props = {
  children: React.Node
}

const Feature = ({ children }: Props) => (
  <div className="feature">
    {children}
  </div>
);

export default Feature;
