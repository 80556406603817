// @flow
type Args = {
  toggleModal: Function,
  modalId: string,
  clearMessage: Function,
  messageId: string,
}

export default ({
  toggleModal, modalId, clearMessage, messageId,
}: Args) => {
  toggleModal({ modalId, isOpen: false });
  if (messageId) {
    clearMessage(messageId);
  }
};
