// @flow
import React from 'react';
import GoogleReCAPTCHA from 'react-google-recaptcha';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// styles
import './recaptcha.scss';

import env from 'CONFIG/env';

const { googleReCaptchaKey } = env;

const ReCAPTCHA = ({ setRecaptcha }: Function) => <div className="recaptcha-container"><GoogleReCAPTCHA sitekey={googleReCaptchaKey} onChange={res => setRecaptcha(res)} /></div>;

const mapDispatchToProps = dispatch => ({
  setRecaptcha: bindActionCreators(actionCreators.setRecaptcha, dispatch),
});

export default connect(null, mapDispatchToProps)(ReCAPTCHA);
