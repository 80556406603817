// @flow
import React from 'react';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'ACTIONS';

// components
import ModalContent from './ModalContent';

// styles
import './modal.scss';

import toggleModalNContent from './toggleModalNContent';

type Props = {
  modalId: string,
  children: React.Node,
  className?: string,
  modals: Object,
  style?: Object,
  toggleModal: Function,
  clearMessage: Function,
  onCloseFunc?: Function,
  messageId?: string,
}

const Modal = ({
  modalId, children, className, modals, toggleModal, clearMessage, messageId, onCloseFunc, style, ...rest
}: Props) => {
  if (modals.isAnyModalOpen) {
    document.getElementsByTagName('body')[0].classList.add('hide-overflow');
  } else {
    document.getElementsByTagName('body')[0].classList.remove('hide-overflow');
  }

  return (
    <div
      className={`main_modal ${className}`}
      style={{
        display: modals[modalId] && modals[modalId].isOpen ? 'block' : 'none',
        ...style,
      }}
      {...rest}
    >
      <div
        className="close_modal_background"
        onClick={() => {
          toggleModalNContent({
            toggleModal, modalId, clearMessage, messageId,
          });
          if (onCloseFunc) {
            onCloseFunc();
          }
        }}
      />
      <ModalContent modalId={modalId} messageId={messageId} onCloseFunc={onCloseFunc}>
        {children}
      </ModalContent>
    </div>
  );
};

Modal.defaultProps = {
  className: '',
  messageId: '',
  style: {},
  onCloseFunc: () => null,
};

const mapStateToProps = ({ uiInteractions }) => {
  const { modals } = uiInteractions;
  return { modals };
};
const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(actionCreators.toggleModal, dispatch),
  clearMessage: bindActionCreators(actionCreators.clearMessage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
