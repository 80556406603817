// @flow
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import Head from 'next/head';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';

// components
import Footer from 'COMPONENTS/Landing/Footer';
import SuccessMessage from 'COMPONENTS/Message';
import { Link } from 'ROUTES';

// styles
import 'bootstrap/scss/bootstrap.scss';
import 'STYLES/main.scss';
import './register.scss';

// other
import { rLandingFooter } from 'REQUESTS';
import { registerValidation } from 'VALIDATION';
import env from 'CONFIG/env';
import { actionCreators } from 'ACTIONS';
import Form from './Form';
import Button from "COMPONENTS/Button";

// data

const { imagesPath, userLanguage } = env;

class Register extends Component {
  static async getInitialProps({
    valid, formData, toggleLoading, loading, handleSubmit, error, recaptchaResponse, removeRecaptcha,
  }) {
    return {
      valid, formData, toggleLoading, loading, handleSubmit, error, recaptchaResponse, removeRecaptcha,
    };
  }

  static propTypes = {
    valid: PropTypes.bool.isRequired,
    formData: PropTypes.object.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    removeRecaptcha: PropTypes.func.isRequired,
    error: PropTypes.string,
    recaptchaResponse: PropTypes.string,
  };

  state = {
    isSuccess: false,
  };

  render() {
    const {
      valid, formData, toggleLoading, loading, handleSubmit, error, recaptchaResponse, removeRecaptcha,
    } = this.props;
    const { isSuccess } = this.state;

    return (
      <NoSSR>
        <I18n>
          {
          t => (
              <div className="register-component">
                {
                  isSuccess ? (
                    <SuccessMessage
                      className="form_container"
                      title={t('back.auth_register_successfully_title')}
                      text={<p>
                        <p>{t('back.auth_register_successfully_text')}</p>
                        <p className="login_section1">
                          <Button
                            className="bgBlueButton">
                        <Link route="Login">
                        {t('back.sign_in')}
                        </Link></Button>
                        </p></p>}
                    />
                  ) : (
                      <div className="CardWhite RegisterGrid">

                        <div className="RegisterImage">
                          <a href="https://unishop.ge">
                            <img src={`${imagesPath}/unishop_logo_black.svg`} className="logo positionAbsolute" />
                          </a>
                          <img src={`${imagesPath}/auth/Register.svg`} />
                        </div>
                        <div className="RegisterFormSection">
			            <a href="https://unishop.ge">
                            <img src={`${imagesPath}/unishop_logo_black.svg`} className="logo positionAbsolute1" />
                          </a>
                          <div className="right">
                            <Form
                              valid={valid}
                              recaptchaResponse={recaptchaResponse}
                              formData={formData}
                              imagesPath={imagesPath}
                              toggleLoading={toggleLoading}
                              loading={loading}
                              callSuccess={() => this.setState({ isSuccess: true })}
                              handleSubmit={handleSubmit}
                              error={error}
                              t={t}
                              removeRecaptcha={removeRecaptcha}
                            />
                          </div>
                          <p className="login_section">
                            <span>{t('back.have_account')} </span>
                            <Link route="Login">
                              {t('back.sign_in')}
                            </Link>
                          </p>
                          {/*<p className="beforeAfterSection">*/}
                          {/*  <span>{t('back.or_connect_using')} </span>*/}
                          {/*</p>*/}
                          {/*<div className="Soc_Section">*/}
                          {/*  <Button className="socButton FbBlue">*/}
                          {/*    <img className="IconSOC F" src={`${imagesPath}/FacebookL.svg`} />*/}
                          {/*    <img className="IconSOC F1" src={`${imagesPath}/whiteF.svg`} />*/}
                          {/*  </Button>*/}
                          {/*  <Button className="socButton GRed">*/}
                          {/*    <img className="IconSOC G" src={`${imagesPath}/google.png`} />*/}
                          {/*    <img className="IconSOC G1" src={`${imagesPath}/whiteG.svg`} />*/}
                          {/*  </Button>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                  )
                }
              </div>
          )
        }
        </I18n>
      </NoSSR>
    );
  }
}

Register.defaultProps = {
  error: '',
  recaptchaResponse: '',
};

Register = reduxForm({
  form: 'register',
  validate: registerValidation,
})(Register);

const mapStateToProps = ({
  form, uiInteractions, user, landing,
}) => {
  const registerForm = form.register;
  const formData = { ...registerForm };
  const { loading } = uiInteractions;
  const { isSuccess } = user;
  const { recaptchaResponse } = landing;
  return {
    formData,
    loading,
    isSuccess,
    recaptchaResponse,
    landing,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleLoading: bindActionCreators(actionCreators.toggleLoading, dispatch),
  callSuccess: bindActionCreators(actionCreators.callSuccess, dispatch),
  removeRecaptcha: bindActionCreators(actionCreators.removeRecaptcha, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
