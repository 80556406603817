// @flow
import React from 'react';

import env from 'CONFIG/env';

import './message.scss';

const { imagesPath } = env;

type Props = {
  title: string,
  text?: string,
  className?: string,
  messageType?: string,
};

const Message = ({
  className, title, text, messageType, ...rest
}: Props) => (
  <div className={`message ${className}`} {...rest}>
    <img src={`${imagesPath}/${messageType === 'error' ? 'error_icon' : 'check_icon'}.svg`} />
    <h4>{ title }</h4>
    {
      text && <p className="small-note">{ text }</p>
    }
  </div>
);

Message.defaultProps = {
  className: '',
  messageType: 'success',
  text: '',
};

export default Message;
